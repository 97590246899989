<div
  class="w-full min-h-full h-screen overflow-y-auto bg-slate-200 p-4 flex flex-col justify-start"
>
  <bidvest-business-case-header>
    <span
      ngProjectAs="business-case-header-back-btn"
      [routerLink]="routes['BUSINESS_CASES'] + '/' + businessCaseId"
    >
      <button mat-icon-button>
        <mat-icon>arrow_back</mat-icon>
      </button>
    </span>
    <span
      ngProjectAs="business-case-header-title"
      [matTooltip]="businessCase?.businessCaseName!"
    >
      {{ businessCase?.businessCaseName | titlecase }}
    </span>
    <span ngProjectAs="business-case-header-number">
      {{ businessCase?.referenceNumber }}
    </span>
    <span ngProjectAs="business-case-header-actions">
      @if (businessCase?.activeStageName === 'CUSTOMER_FINANCE_REVIEW' &&
      businessCase?.activeStageStatus === 'IN_REVIEW' && isReviewer ||
      businessCase?.activeStageName === 'CUSTOMER_FINAL_REVIEW' &&
      businessCase?.activeStageStatus === 'IN_REVIEW' && isReviewer ){
      <button
        mat-stroked-button
        color="accent"
        class="mr-4"
        (click)="rejectSoftServiceQuote()"
      >
        Reject soft services
      </button>
      <button
        mat-flat-button
        color="accent"
        (click)="approveSoftServiceQuote()"
      >
        Approve soft services
      </button>
      } @if(businessCase?.activeStageName === 'CUSTOMER_FINANCE_REVIEW' &&
      businessCase?.activeStageStatus === 'DRAFT' && isEditor ||
      businessCase?.activeStageName === 'CUSTOMER_FINAL_REVIEW' &&
      businessCase?.activeStageStatus === 'DRAFT' && isEditor ) {
      <button mat-flat-button color="accent" (click)="review()">
        Submit for review
      </button>
      }
    </span>
  </bidvest-business-case-header>
  <div
    class="bg-white rounded-xl p-8 w-full min-h-[businessCase?.activeStageStatus === 'REJECTED' && isEditor ? 105% : 95%] pb-4 flex flex-col justify-start"
  >
    @if (businessCase?.activeStageName === 'CUSTOMER_FINANCE_REVIEW' &&
    businessCase?.activeStageStatus === 'REJECTED' && isEditor) {
    <span class="flex rounded-md bg-[#B80000] mb-2 p-4">
      <div class="flex">
        <div class="pr-2">
          <img height="=20" width="20" src="assets/Alert.svg" />
        </div>
        <div class="flex flex-col">
          <p class="font-semibold text-white">Soft service quote rejected</p>
          <p class="text-white">
            {{
              extractRejectionReason(businessCase?.activeStageRejectionReason)
            }}
          </p>
          <button
            class="red-button-text w-[186px]"
            mat-flat-button
            (click)="returnToSoftServicesStage()"
          >
            Modify soft services
          </button>
        </div>
      </div>
    </span>
    }
    <div>
      <img class="header-logo" src="assets/bidvest-header-logo.png" />
    </div>
    <div class="flex flex-col" *ngIf="businessCaseId && softServiceQuote">
      <h4 class="text-blue-800 font-bold text-2xl pb-8">Soft services quote</h4>
      <div class="grid grid-cols-2 gap-2 w-[400px] py-4">
        <div>
          <p class="text-gray-800 font-semibold">Quote date</p>
          <p class="text-gray-800 font-semibold">Implementation date</p>
          <p class="text-gray-800 font-semibold">Site</p>
          <p class="text-gray-800 font-semibold">Site name</p>
          <p class="text-gray-800 font-semibold">Region</p>
        </div>
        <div class="flex flex-col items-end">
          <p class="text-gray-400">
            {{ softServiceQuote.quoteDate }}
          </p>
          <p class="text-gray-400">
            {{ softServiceQuote.implementationDate }}
          </p>
          <p class="text-gray-400">
            {{ softServiceQuote.site }}
          </p>
          <p class="text-gray-400">
            {{ softServiceQuote.siteName }}
          </p>
          <p class="text-gray-400">
            {{ softServiceQuote.region }}
          </p>
        </div>
      </div>
    </div>
    <div class="py-4" *ngIf="softServiceQuote">
      <bidvest-services-quotes-table
        [softServiceQuote]="softServiceQuote"
      ></bidvest-services-quotes-table>
    </div>
    <div class="pt-4">
      <p class="footer-text">
        The above excludes the impact of Inflation or any other increases which
        may be agreed to between Absa and the Supplier
      </p>
      <p class="footer-text">
        The above quote has been prepared using the Service Standards agreed
        between Absa and the Supplier as documented in the Changes to the scopes
        of work in the engineering services standards (INFO 7) Record of
        Decision 10 December 2020.
      </p>
      <p class="footer-text">
        The above values may differ from the Change Note/Business Case Appendix
        2 due to Excel day rounding versus system rounding.
      </p>
    </div>
  </div>
</div>
