<div class="w-full min-h-full h-screen overflow-y-auto bg-slate-200 p-4 flex flex-col justify-start">
  <bidvest-business-case-header *ngIf="businessCaseDetails">
    <span ngProjectAs="business-case-header-back-btn" [routerLink]="routes['BUSINESS_CASES'] + '/' + businessCaseId">
      <button mat-icon-button>
        <mat-icon>arrow_back</mat-icon>
      </button>
    </span>
    <span ngProjectAs="business-case-header-title" [matTooltip]="businessCaseDetails.businessCaseName!">
      {{ businessCaseDetails.businessCaseName  }}
    </span>
    <span ngProjectAs="business-case-header-number" #refNo>
      {{ businessCaseDetails.referenceNumber }}
    </span>
    <span ngProjectAs="business-case-header-actions">
      @if(businessCaseDetails.activeStageName === 'COST_SOFT_SERVICES' &&
      businessCaseDetails.activeStageStatus === 'DRAFT' && isEditor ||
      businessCaseDetails.activeStageName === 'COST_SOFT_SERVICES' &&
      businessCaseDetails.activeStageStatus === 'REJECTED' && isEditor ||
      businessCaseDetails.activeStageName === 'CUSTOMER_FINANCE_REVIEW' &&
      businessCaseDetails.activeStageStatus === 'REJECTED' && isEditor) {
      <button mat-stroked-button [disabled]="disableButton" color="accent" (click)="review()" class="mr-4">
        Submit for review
      </button>
      }
    </span>
    <span ngProjectAs="business-case-header-actions">
      @if (businessCaseDetails.activeStageName === 'COST_SOFT_SERVICES' &&
      businessCaseDetails.activeStageStatus === 'IN_REVIEW' && isReviewer){
      <button mat-stroked-button color="accent" class="mr-4" (click)="rejectSoftServicesStage()">
        Reject soft services
      </button>
      <button mat-flat-button color="accent" (click)="approveSoftServicesStage()">
        Approve soft services
      </button>
      }
    </span>
  </bidvest-business-case-header>

  <div class="rounded p-6 bg-white h-[95vh]">
    <div class="flex flex-col justify-start">
      <h4 class="font-bold text-primary-blue text-2xl">Add soft service</h4>
      <p class="sub-heading-text">
        Select soft services you wish to add to this business case
      </p>
    </div>
    <div class="mt-4">
      <div class="flex justify-between gap-4 items-center bg-white px-2 py-4">
        <div class="bg-blue">
        </div>
        @if (businessCaseDetails?.activeStageName === 'COST_SOFT_SERVICES' &&
        businessCaseDetails?.activeStageStatus === 'DRAFT' && isEditor || 
        businessCaseDetails?.activeStageName === 'COST_SOFT_SERVICES' &&
        businessCaseDetails?.activeStageStatus === 'REJECTED' && isEditor ||
        businessCaseDetails?.activeStageName === 'CUSTOMER_FINANCE_REVIEW' &&
        businessCaseDetails?.activeStageStatus === 'REJECTED' && isEditor
        ) {
        <div class="flex flex-row gap-2">
          <button class="underlined-button" color="accent" (click)="showActivityLogDialog()">+ Add comment</button>
          <button mat-stroked-button class="border-button" color="accent" (click)="softServicesOverlay()">+ Add soft service</button>
        </div>
        }
      </div>
    </div>
    <div class="w-full overflow-x-auto">
      <table mat-table [dataSource]="dataSource.data">
        <ng-container matColumnDef="code">
          <th mat-header-cell *matHeaderCellDef>Code</th>
          <td mat-cell *matCellDef="let element">{{ element.rateCard.code }}</td>
        </ng-container>

        <ng-container matColumnDef="serviceName">
          <th mat-header-cell *matHeaderCellDef>Service name</th>
          <td mat-cell *matCellDef="let element">{{ element.rateCard.serviceName }}</td>
        </ng-container>

        <ng-container matColumnDef="category">
          <th mat-header-cell *matHeaderCellDef>Category</th>
          <td mat-cell *matCellDef="let element">
            <span class="px-2 py-1 rounded-md">{{ transformToSentenceCase(element.rateCard.category) }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="subCategory">
          <th mat-header-cell *matHeaderCellDef>Sub Category</th>
          <td mat-cell *matCellDef="let element">
            {{ transformToSentenceCase(element.rateCard.subCategory) }}
          </td>
        </ng-container>

        <ng-container matColumnDef="unitCost">
          <th mat-header-cell *matHeaderCellDef>Unit cost</th>
          <td mat-cell *matCellDef="let element">
            <span class="px-2 py-1 flex flex-row">
              <p class="text-primary-blue px-1">R </p> {{ element.rateCard.unitCost }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="uom">
          <th mat-header-cell *matHeaderCellDef>UOM</th>
          <td mat-cell *matCellDef="let element">
            <span class="px-2 py-1">{{ transformToSentenceCase(element.rateCard.unitOfMeasure) }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="quantity">
          <th mat-header-cell *matHeaderCellDef>Quantity</th>
          <td mat-cell *matCellDef="let element">
            {{ element.quantity }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <tr class="w-full" *matNoDataRow>
          <td [colSpan]="displayedColumns.length">
            <div class="h-96 flex flex-row justify-center items-center font-bold">
              No Data
            </div>
          </td>
        </tr>
      </table>

      @if (dataSource.data.length > 0) {
      <mat-paginator [length]="resultsLength" [pageSize]="size" aria-label="Select page">
      </mat-paginator>
      }
    </div>
  </div>
</div>