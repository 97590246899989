import { Injectable } from '@angular/core';
import {
  GlobalPositionStrategy,
  Overlay,
  OverlayConfig,
  OverlayRef
} from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';

@Injectable({
  providedIn: 'root'
})
export class OverlayService {
  private overlayRef!: OverlayRef | null;
  private defaultConfig: OverlayConfig = {
    width: '450px',
    height: '600px',
    backdropClass: 'bg-slate-400/50',
    panelClass: ['overflow-y-auto', 'rounded-lg'],
    hasBackdrop: true,
    positionStrategy: new GlobalPositionStrategy().bottom('20px').right('15px'),
    scrollStrategy: this.overlay.scrollStrategies.block()
  };

  constructor(private overlay: Overlay) {}

  public open(
    component: ComponentType<any>,
    data?: { key: string; value: any },
    config = this.defaultConfig
  ): void {
    if (this.overlayRef) {
      this.close();
    }

    this.overlayRef = this.overlay.create(config);

    const portal = new ComponentPortal(component);
    const componentRef = this.overlayRef.attach(portal);

    if (data) {
      componentRef.setInput(data.key, data.value);
    }

    componentRef.instance.overlayRef = this.overlayRef;

    this.overlayRef.backdropClick().subscribe(() => this.close());
  }

  public close(): void {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }
}
