<div class="rounded p-6 bg-white">
  <form [formGroup]="businessCaseForm" class="flex flex-col w-full">
    <section class="grid grid-cols-12 gap-4 w-full mb-4">
      <div
        class="col-span-12 rounded bg-slate-100 p-4 flex flex-row justify-between items-center"
      >
        <h4 class="font-semibold">Details</h4>
        <ng-container *ngIf="!isReadOnly()">
          <button
            mat-button
            *ngIf="!isBusinessCaseDetailsEditMode"
            (click)="toggleBusinessCaseEdit()"
          >
            <mat-icon>edit_square</mat-icon>
          </button>
          <button
            mat-button
            *ngIf="isBusinessCaseDetailsEditMode"
            (click)="updateBusinessCase()"
          >
            <mat-icon>save</mat-icon>
          </button>
        </ng-container>
      </div>

      <div class="col-span-12 flex flex-col mb-4 pt-8">
        <mat-form-field appearance="outline" floatLabel="always" class="w-full">
          <mat-label>Select Business Case Action Type</mat-label>
          <mat-select formControlName="businessCaseActionType">
            @for (type of businessCaseActionTypes; track type) {
            <mat-option [value]="type">{{
              type.trim().split('_').join(' ')
            }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-span-12">
        <mat-divider></mat-divider>
      </div>

      <mat-form-field
        appearance="outline"
        floatLabel="always"
        class="col-span-12 mt-8 mb-4"
      >
        <mat-label>Business case name</mat-label>
        <input type="text" matInput formControlName="name" />
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        floatLabel="always"
        class="col-span-12"
      >
        <mat-label>Implementation Date </mat-label>

        <input
          matInput
          [matDatepicker]="picker"
          formControlName="implementationDate"
        />

        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        floatLabel="always"
        class="col-span-12"
      >
        <mat-label>Business case description </mat-label>
        <textarea matInput rows="5" formControlName="description"></textarea>
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        floatLabel="always"
        class="col-span-12 mb-4"
      >
        <mat-label>Building number</mat-label>
        <input type="text" matInput formControlName="buildingNumber" />
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        floatLabel="always"
        class="col-span-6 mb-4"
      >
        <mat-label>Town </mat-label>
        <input type="text" matInput formControlName="town" />
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        floatLabel="always"
        class="col-span-6 mb-4"
      >
        <mat-label>Province </mat-label>
        <mat-select formControlName="province">
          @for (province of provinces; track $index) {
          <mat-option [value]="province">{{ province }}</mat-option>
          }
        </mat-select>
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        floatLabel="always"
        class="col-span-6"
      >
        <mat-label>Outlet </mat-label>
        <input type="text" matInput formControlName="outlet" />
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        floatLabel="always"
        class="col-span-6"
      >
        <mat-label>Outlet Type</mat-label>
        <mat-select formControlName="outletType">
          @for (outletType of outletTypes; track $index) {
          <mat-option [value]="outletType">{{ outletType }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </section>
  </form>

  <mat-divider></mat-divider>

  <section class="flex flex-col w-full my-4">
    <div
      class="rounded w-full bg-slate-100 p-4 flex flex-row justify-between items-center"
    >
      <h4 class="font-semibold">Uploads</h4>

      <ng-container *ngIf="!isReadOnly()">
        <button
          mat-button
          *ngIf="!isDocumentUploadEditMode"
          (click)="toggledocumentUploadEdit()"
        >
          <mat-icon>edit_square</mat-icon>
        </button>
        <button
          mat-button
          *ngIf="isDocumentUploadEditMode"
          (click)="toggledocumentUploadEdit()"
        >
          <mat-icon>save</mat-icon>
        </button>
      </ng-container>
    </div>

    <div class="flex flex-row justify-start py-4">
      <ng-container *ngIf="isDocumentUploadEditMode">
        <ng-container *ngFor="let document of businessCaseDetails()?.documents">
          <div class="flex flex-col mr-4">
            <bidvest-file-upload
              (uploadSuccess)="requiredDocs.cdaLayout = true"
              [updateMode]="true"
              [allowedMimeTypes]="['.pdf']"
              [businessCaseId]="businessCaseDetails()?.id!"
              [url]="'documents/' + document.documentType + '/' + document.id"
              [required]="true"
            ></bidvest-file-upload>
            <small class="font-semibold">{{
              document.documentType.split('_').join(' ') | uppercase
            }}</small>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="!isDocumentUploadEditMode">
        <ng-container *ngFor="let document of businessCaseDetails()?.documents">
          <div
            class="flex flex-col justify-between mr-4 border-2 rounded p-4 gap-y-6 w-4/12"
          >
            <span class="flex flex-col">
              <small class="font-semibold text-xs p-1 bg-slate-200 rounded mb-2"
                >Document Name</small
              >
              <small>{{ document.documentName }}</small>
            </span>

            <span class="flex flex-col">
              <small class="font-semibold text-xs p-1 bg-slate-200 rounded mb-2"
                >Document Type</small
              >
              <small class="text-xs">{{
                document.documentType.split('_').join(' ')
              }}</small>
            </span>

            <button
              mat-flat-button
              color="accent"
              (click)="downloadDocument(document)"
            >
              Download
            </button>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </section>

  <mat-divider></mat-divider>

  <section class="grid grid-cols-12 gap-4 w-full mt-4">
    <div
      class="rounded col-span-12 bg-slate-100 p-4 flex flex-row justify-between items-center"
    >
      <h4 class="font-semibold">Collaborators</h4>

      <ng-container *ngIf="!isReadOnly()">
        <button
          mat-button
          *ngIf="!isCollaboratorsEditMode"
          (click)="toggleCollaboratorsEdit()"
        >
          <mat-icon>edit_square</mat-icon>
        </button>
        <button
          mat-button
          *ngIf="isCollaboratorsEditMode"
          (click)="toggleCollaboratorsEdit()"
        >
          <mat-icon>save</mat-icon>
        </button>
      </ng-container>
    </div>

    <div class="col-span-4 my-4">
      <h4 class="text-sm uppercase font-semibold text-gray-500">Stages</h4>
    </div>

    <div class="col-span-4 my-4">
      <h4 class="text-sm uppercase font-semibold text-gray-500">Stage Owner</h4>
    </div>

    <div class="col-span-4 my-4">
      <h4 class="text-sm uppercase font-semibold text-gray-500">Reviewers</h4>
    </div>

    <ng-container *ngIf="!isCollaboratorsEditMode">
      <ng-container *ngFor="let stage of businessCaseStages">
        <div class="col-span-12 grid grid-cols-12 gap-4 mt-8">
          <div class="col-span-4 flex flex-col justify-center">
            <h4 class="font-bold">
              {{ stage.stageName.split('_').join(' ') }}
            </h4>
          </div>

          <div class="col-span-4 flex flex-col">
            <p class="text-sm font-semibold">Stage Owner</p>
            <mat-chip-set class="w-full">
              <mat-chip>{{ getStageOwner(stage) }}</mat-chip>
            </mat-chip-set>
          </div>

          <div class="col-span-4 flex flex-col">
            <p class="text-sm font-semibold">Reviewers</p>
            <mat-chip-set class="w-full">
              @for (reviewer of bcReviewers[stage.stageName]; track reviewer) {
              <mat-chip>{{ reviewer }}</mat-chip>
              }
            </mat-chip-set>
          </div>
        </div>

        <div class="col-span-12 my-4">
          <mat-divider></mat-divider>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="isCollaboratorsEditMode">
      <ng-container
        *ngFor="let collaborator of businessCaseDetails()?.collaborators"
      >
        <bidvest-collaborators-selector
          class="col-span-12"
          [businessCaseAccounts]="businessCaseAccounts"
          [collaboratorsData]="collaborator"
          (collaboratorsSelection)="getCollaboratorSelection($event)"
        ></bidvest-collaborators-selector>

        <div class="col-span-12 my-4">
          <mat-divider></mat-divider>
        </div>
      </ng-container>
    </ng-container>
  </section>
</div>
