import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDividerModule } from '@angular/material/divider';
import { BusinessCaseActivityLogComponent } from '../business-case-activity-log/business-case-activity-log.component';
import { BusinessCaseService } from '../../services/business-case.service';
import { firstValueFrom } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { IBusinessCaseDetails } from '../../interfaces/business-case-details.interface';
import { BusinessCaseHeaderComponent } from '../../../../../../../shared/src/lib/shared/components/business-case-header/business-case-header.component';
import { ToastrService } from 'ngx-toastr';
import { IRateCardDataType } from '../../models/rate-card-data';
import { ActivatedRoute, ParamMap, Router, RouterLink } from '@angular/router';
import { backOfficeRouteKeys, OverlayService } from '@bidvest/shared';
import { SoftServicesService } from '../../services/soft-services.service';
import { ISoftServicesBusinessCaseDetails } from '../../models/soft-service-business-case-details';
import { IBusinessCaseStageConfig } from '../../interfaces/business-case-stage-config.interface';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable,
  MatTableDataSource,
  MatTableModule
} from '@angular/material/table';
import { FormsModule } from '@angular/forms';
import { MatTooltip } from '@angular/material/tooltip';
import { IViewSoftServices } from '../../models/view-soft-services';
import { ReasonForRejectionOverlayComponent } from '@bidvest/features';
import { BusinessCaseReviewPayload } from '../../interfaces/business-case-review-payload.interface';
import { BusinessCaseReviewStatus } from '../../enums/business-case-review-status.enum';
import { AddSoftServicesUpdate } from '../../models/add-soft-services-update.interface';

const rateCardInputs = {
  currentPage: 1,
  size: 100,
  searchTerm: null,
  category: ''
};

@Component({
  selector: 'bidvest-soft-service-read-only',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatTabsModule,
    MatDividerModule,
    BusinessCaseHeaderComponent,
    RouterLink,
    MatCell,
    FormsModule,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderRow,
    MatHeaderRowDef,
    MatRow,
    MatRowDef,
    MatTable,
    MatTooltip,
    MatTableModule
  ],
  templateUrl: './soft-services-read-only.component.html',
  styleUrl: './soft-services-read-only.component.scss'
})
export class SoftServicesReadOnlyComponent implements OnInit {
  public dataSource = new MatTableDataSource<IRateCardDataType>([]);
  public businessCase: IBusinessCaseDetails | null = null;
  public routes: { [key: string]: string } = backOfficeRouteKeys;
  public businessCaseStagesConfigs: Array<IBusinessCaseStageConfig> = [];
  // @ts-ignore
  public rateCardData: IViewSoftServices;
  public businessCaseId!: number;
  public softServicesData: Array<AddSoftServicesUpdate> = [];
  public displayedColumns: Array<string> = [
    'code',
    'serviceName',
    'category',
    'subCategory',
    'unitCost',
    'uom',
    'quantity'
  ];
  public isReadOnlyView!: boolean;

  constructor(
    private readonly _businessCaseService: BusinessCaseService,
    private readonly _loader: NgxUiLoaderService,
    private readonly _toastr: ToastrService,
    private readonly _route: ActivatedRoute,
    private readonly _softService: SoftServicesService,
    private readonly _dialog: MatDialog,
    private readonly _overlayService: OverlayService,
    private readonly _router: Router
  ) {}

  public async ngOnInit(): Promise<void> {
    this._loader.start();

    const routeParams: ParamMap = await firstValueFrom(this._route.paramMap);
    this.businessCaseId = routeParams.get('id') as unknown as number;

    this.isReadOnlyView = this._businessCaseService.isReadOnlyView(routeParams);

    // load the business case soft services for review
    try {
      this.businessCaseStagesConfigs = await firstValueFrom(
        this._businessCaseService.fetchBusinessCaseStagesConfigs(
          this.businessCaseId
        )
      );
      this.businessCase = await firstValueFrom(
        this._businessCaseService.fetchBusinessCaseById(this.businessCaseId)
      );

      if (this.isReviewer()) {
        await this.getSoftServicesForReview();
      } else {
        await this.getAvailableServices();
      }
    } catch (error) {
      console.log(error);
    } finally {
      this._loader.stop();
    }
  }

  public async getSoftServicesForReview(): Promise<void> {
    // get all rate card items visible
    this.rateCardData = await this._softService.fetchAvailableRateCardServices({
      currentPage: 1,
      category: '',
      size: 10000,
      searchTerm: ''
    });
    // loop through added soft services
    this.softServicesData =
      await this._softService.fetchBusinessCaseSoftServices(
        this.businessCaseId
      );

    const rateCards: Array<IRateCardDataType> = new Array<IRateCardDataType>();

    this.rateCardData.rateCards.forEach((rateCardData) => {
      this.softServicesData.forEach((ss) => {
        if (ss.rateCard.id === rateCardData.id) {
          rateCards.push(rateCardData);
        }
      });
    });
    // build a new data source
    this.dataSource = new MatTableDataSource(rateCards);
    setTimeout(async () => {
      await this.getAddedSoftServices();
    }, 1500);
  }

  public async getAddedSoftServices(): Promise<void> {
    if (!this.softServicesData || this.softServicesData.length == 0) {
      this.softServicesData =
        await this._softService.fetchBusinessCaseSoftServices(
          this.businessCaseId
        );
    }

    const inputElements = document.querySelectorAll(
      'span[data-id]'
    ) as NodeListOf<HTMLInputElement>;
    // loop through and update the value
    this.softServicesData.forEach((value) => {
      inputElements.forEach((input) => {
        if (Number(input.getAttribute('data-id')) === value.rateCard.id) {
          input.innerText = String(value.quantity);
        }
      });
    });
  }

  public async getAvailableServices(): Promise<void> {
    this.rateCardData = await this._softService.fetchAvailableRateCardServices({
      currentPage: 1,
      category: '',
      size: 100000,
      searchTerm: ''
    });

    this.dataSource = new MatTableDataSource(this.rateCardData.rateCards);
    await this.getAddedSoftServices();
  }

  public isReviewer(): boolean {
    return this.businessCaseStagesConfigs.find(
      (config) => config.stageName === this.businessCase?.activeStageName
    )?.canRead as boolean;
  }

  public rejectSoftServicesStage(): void {
    const dialogRef: MatDialogRef<ReasonForRejectionOverlayComponent> =
      this._dialog.open(ReasonForRejectionOverlayComponent, {
        width: '600px',
        height: '450px'
      });

    dialogRef
      .beforeClosed()
      .subscribe(async (data: { reason: string; details: string }) => {
        const payload: BusinessCaseReviewPayload = {
          businessCaseId: this.businessCase?.id as number,
          businessCaseStageId: this.businessCase?.activeStageId as number,
          businessCaseStageReviewStatus: BusinessCaseReviewStatus.REJECTED,
          rejectedReason: data.reason,
          moreDetails: data.details
        };

        try {
          this._loader.start();

          await firstValueFrom(
            this._businessCaseService.rejectBusinessCase(payload)
          );

          this._loader.stop();
          this._toastr.success(
            `Business case ${payload.businessCaseId} has been rejected.`
          );

          await this._router.navigateByUrl(this.routes['BUSINESS_CASES']);
        } catch (error) {
          console.log(error);
          this._toastr.error(
            `There was as issue rejecting the business case with id ${payload.businessCaseId}`
          );
        }
      });
  }

  public async approveSoftServicesStage(): Promise<void> {
    this._loader.start();

    const payload: BusinessCaseReviewPayload = {
      businessCaseId: this.businessCase?.id as number,
      businessCaseStageId: this.businessCase?.activeStageId as number,
      businessCaseStageReviewStatus: BusinessCaseReviewStatus.APPROVED,
      rejectedReason: '',
      moreDetails: ''
    };

    try {
      this._loader.start();

      await firstValueFrom(
        this._businessCaseService.approveBusinessCase(payload)
      );

      this._loader.stop();
      this._toastr.success(
        `Business case ${payload.businessCaseId} has been approved successfully.`
      );

      await this._router.navigateByUrl(this.routes['BUSINESS_CASES']);
    } catch (error) {
      this._toastr.error(
        `There was as issue approving the business case with id ${payload.businessCaseId}`
      );
    }
  }

  public showActivityLogDialog(): void {
    this._overlayService.open(BusinessCaseActivityLogComponent, {
      key: 'businessCaseId',
      value: this.businessCaseId
    });
  }
}
