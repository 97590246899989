<div class="w-full flex flex-row justify-between items-center p-4">
  <h4 class="font-semibold text-primary-blue inline-block">Reassign user business cases</h4>
  <button mat-icon-button matDialogClose>
    <mat-icon>clear</mat-icon>
  </button>
</div>
<div matDialogContent>
  @if (bcGroupKeys.length > 0) {
  <span class="flex flex-col">
    <p class="mb-8">
      <span class="font-bold">{{ data.name }}</span> is still connected to
      business cases
    </p>
    <small
      >This user is connected to business cases listed below, a new stage
      owner/reviewer has to be selected</small
    >
  </span>

  <div class="flex flex-col w-full h-[300px] max-h-[300px] overflow-y-auto">
    @for (key of bcGroupKeys; track key) {
    <div class="flex flex-col">
      <span class="uppercase font-semibold py-4">{{ key }}</span>

      @for (case of bcGroups[key]; track case) {

      <span class="text-xs uppercase mb-2">{{
        case.businessCaseStageName.split('_').join(' ')
      }}</span>

      <mat-form-field appearance="outline" class="w-full">
        @if (case.owner) {
        <mat-label>Stage Owner</mat-label>
        } @else if(case.reviewer) {
        <mat-label>Reviewer</mat-label>
        }
        <mat-select (selectionChange)="getSelectedAccount($event, { owner: case.owner, bc: case })">
          @for (account of getAvailableAccounts(data.userId); track $index) {
            <mat-option [value]="account.id">{{ account.fullName }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
      }
    </div>

    <div class="my-8">
      <mat-divider></mat-divider>
    </div>
    }
  </div>
  } @else {
    <div>There are no business cases to reassign for <span class="font-bold">{{ data.name }}</span></div>
  }
</div>
<div matDialogActions align="end" class="py-4 shadow">
  <button mat-button matDialogClose class="mr-2">Cancel</button>
  <button
    mat-flat-button
    [disabled]="selectedUsers.size !== this.totalReplacementsRequired"
    (click)="submitForm()"
    color="accent">
    Submit
  </button>
</div>
