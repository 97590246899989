import { CommonModule } from '@angular/common';
import { Component, Inject, Input, OnInit, SimpleChanges } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';
import { IEngineeringServciesTabData } from '../../../models/engineering-tab-data';
import { EngineeringServiceTableService } from '../../../services/engineering-table.service';
import {IBusinessCaseOverallGmpImpactViewModel} from "../../../models/business-case-overall-gmp-impact-view-model";
import {RoundOffByTwoPipe} from "../../../../../../../../shared/src/lib/shared/pipes/round-off-by-two.pipe";
import {GmpOverallImpactTypeEnum} from "../../../enums/gmp-overall-impact-type.enum";

@Component({
  selector: 'bidvest-engineering-services-table',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatMenuModule,
    MatCheckboxModule,
    FormsModule,
    MatPaginatorModule,
    RoundOffByTwoPipe
  ],
  templateUrl: './engineering-services-overview-tab.component.html',
  styleUrl: './engineering-services-overview-tab.component.scss'
})
export class EngineeringServicesTabComponent implements OnInit {
  public dataSource!: Array<IBusinessCaseOverallGmpImpactViewModel>;
  public displayedColumns: string[] = [
    'title',
    'gmpBeforeChange',
    'removedAssetNotReplaced',
    'revisedGMPBeforeNewAsset',
    'firstReactivePercentage',
    'finalRevisedGmp',
    'gmpNetImpact'
  ];
  public constructor(
    private _engineeringTableService: EngineeringServiceTableService
  ) {}


  public async getEngineeringTableData() {
    const route = location.pathname.split('/')
    const businessCaseId = route[2]
    this.dataSource = await this._engineeringTableService.getGmpOverallImpact(Number(businessCaseId));
  }
  public ngOnInit() {
    this.getEngineeringTableData();
  }

  protected readonly GmpOverallImpactTypeEnum = GmpOverallImpactTypeEnum;
}
