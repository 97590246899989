<div class="flex flex-row justify-between items-center p-4">
  <h4 class="font-bold text-xl text-primary-blue">Update Asset Pricing</h4>

  <button mat-icon-button matDialogClose>
    <mat-icon>close</mat-icon>
  </button>
</div>

<div matDialogContent>
  <form class="flex flex-col gap-4" [formGroup]="assetPriceUpdateForm">
    <mat-form-field class="w-full" appearance="outline">
      <mat-label>Asset Name</mat-label>
      <input matInput formControlName="assetName" />
    </mat-form-field>

    <mat-form-field class="w-full" appearance="outline">
      <mat-label>High level description</mat-label>
      <input matInput formControlName="highLevelDesc" />
    </mat-form-field>

    <mat-form-field class="w-full" appearance="outline">
      <mat-label>Comment</mat-label>
      <textarea matInput rows="6" formControlName="comment"></textarea>
    </mat-form-field>

    <div class="my-2">
      <mat-divider></mat-divider>
    </div>

    <mat-form-field class="w-full" appearance="outline">
      <mat-label>Monthly fee</mat-label>
      <input matInput type="text" formControlName="monthlyFee" />
      <span matTextPrefix class="font-bold text-primary-blue align-baseline"
        >R&nbsp;</span
      >
    </mat-form-field>

    <mat-form-field class="w-full" appearance="outline">
      <mat-label>Monthly reactive fee with warranty</mat-label>
      <input
        matInput
        type="text"
        formControlName="monthlyReactiveFeeWithWarranty"
      />
      <span matTextPrefix class="font-bold text-primary-blue align-baseline"
        >R&nbsp;</span
      >
    </mat-form-field>

    <mat-form-field class="w-full" appearance="outline">
      <mat-label>Monthly reactive fee without warranty</mat-label>
      <input
        matInput
        type="text"
        formControlName="monthlyReactiveFeeWithoutWarranty"
      />
      <span matTextPrefix class="font-bold text-primary-blue align-baseline"
        >R&nbsp;</span
      >
    </mat-form-field>

    <mat-form-field class="w-full" appearance="outline">
      <mat-label>Quantity</mat-label>
      <input matInput type="number" formControlName="quantity" />
    </mat-form-field>

    <mat-form-field class="w-full" appearance="outline">
      <mat-label>Strategy</mat-label>
      <input matInput type="text" formControlName="strategy" />

      <!-- <mat-select>
        <mat-option></mat-option>
      </mat-select> -->
    </mat-form-field>
  </form>
</div>
<div matDialogActions>
  <button
    mat-flat-button
    class="w-full m-4"
    color="accent"
    (click)="submitAssetPrice()"
    [disabled]="!assetPriceUpdateForm.valid"
  >
    Submit
  </button>
</div>
