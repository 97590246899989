import {
  AfterViewInit,
  Component,
  effect,
  EventEmitter,
  input,
  InputSignal,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialog } from '@angular/material/dialog';
import { RejectAssetPriceDialogComponent } from '../../dialogs/reject-asset-price-dialog/reject-asset-price-dialog.component';
import { IBusinessCaseAsset } from '../../../interfaces/business-case-asset.interface';
import { AssetListService } from '../../../services/asset-list.service';
import { firstValueFrom, Subject, takeUntil } from 'rxjs';
import { IBusinessCaseDetails } from '../../../interfaces/business-case-details.interface';
import { UpdateAssetPriceDialogComponent } from '../../dialogs/update-asset-price-dialog/update-asset-price-dialog.component';
import { AssetReplaceOverlayComponent } from '../../overlays/asset-replacing-overlay/asset-replacing-overlay.component';
import { IEquipmentRetrievalModel } from '../../../models/asset-list-equipment';
import { IBusinessCaseStageConfig } from '../../../interfaces/business-case-stage-config.interface';
import { BusinessCaseService } from '../../../services/business-case.service';
import { AssetDecliningOverlayComponent } from '../../overlays/asset-declining-overlay/asset-declining-overlay.component';
import { FormsModule } from '@angular/forms';
import { IProcuredAssetPrices } from '../../../interfaces/procured-asset-prices.interfaces';
import { ToastrService } from 'ngx-toastr';
import { IProcurementPriceList } from '../../../interfaces/procurement-price-list.interface';
import { CustomToastrService } from '../../../services/custom-toastr.service';

@Component({
  selector: 'bidvest-asset-pricing-table',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    MatButtonModule,
    FormsModule,
    MatIconModule
  ],
  templateUrl: './asset-pricing-table.component.html',
  styleUrl: './asset-pricing-table.component.scss'
})
export class AssetPricingTableComponent
  implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild(MatPaginator) public paginator!: MatPaginator;

  public dataSource = new MatTableDataSource<IBusinessCaseAsset>();
  public displayedColumns: Array<string> = [
    'equipmentId',
    'discipline',
    'buildingId',
    'highLevelDescription',
    'monthlyFee',
    'monthlyReactiveFeeWithWarranty',
    'monthlyReactiveFeeWithoutWarranty',
    'status',
    'strategy',
    'action',
    'flaggingAssetPrice',
    'approvingAssetPrice',
    'rejectionOfAssets'
  ];

  public procurementOnlyColumns: Array<string> = [
    'equipmentId',
    'discipline',
    'buildingId',
    'monthlyFee',
    'monthlyReactiveFeeWithWarranty',
    'monthlyReactiveFeeWithoutWarranty',
    'status',
    'strategy',
    'declinedReasons',
    'rejectionOfAssets'
  ];

  public data: InputSignal<
    | { businessCase: IBusinessCaseDetails; assets: Array<IBusinessCaseAsset> }
    | undefined
  > = input();
  public assetsToUpdatePrice: Array<IBusinessCaseAsset> = [];
  public equimentList: Array<IEquipmentRetrievalModel> = [];
  public businessCaseId!: number;
  public assetDetails: Array<IBusinessCaseAsset> | undefined = [];
  @Output()
  public assetDeclined: EventEmitter<void> = new EventEmitter();
  @Output() public procurementAssetsEmitter: EventEmitter<Array<IProcurementPriceList>> = new EventEmitter();
  public constructor(
    private _dialog: MatDialog,
    private _assetListService: AssetListService,
    private readonly _businessCaseService: BusinessCaseService,
    private readonly _toaster: ToastrService,
    private readonly _toast: CustomToastrService
  ) {
    effect(() => {
      this.dataSource.data = this.data()?.assets as Array<IBusinessCaseAsset>;
      this.businessCaseId = this.data()?.businessCase.id as number;
    });

  }

  public async ngOnInit() {
    this.isEditor();
    this.isReviewer();
    this.submitPriceArray();
    this.businessCaseId = this.data()?.businessCase.id as number;
    this.businessCaseStagesConfigs = await firstValueFrom(
      this._businessCaseService.fetchBusinessCaseStagesConfigs(
        this.businessCaseId
      )
    );
    this.businessCase = await firstValueFrom(
      this._businessCaseService.fetchBusinessCaseById(this.businessCaseId)
    );

  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private _destroy$: Subject<void> = new Subject();
  public businessCaseStagesConfigs: Array<IBusinessCaseStageConfig> = [];
  public businessCaseDetails: IBusinessCaseDetails | undefined;
  public businessCase: IBusinessCaseDetails | null = null;
  public monthlyFees: number = 0;
  public monthlyReactiveFeeWithWarranty: number = 0;
  public monthlyReactiveFeeWithoutWarranty: number = 0;

  public async ngAfterViewInit(): Promise<void> {
    this.dataSource.paginator = this.paginator;

    this.assetsToUpdatePrice = [
      ...((
        await this._assetListService.getAssetsForPricing(
          this.data()?.businessCase.id as number
        )
      ).assetRetrievalList as Array<IBusinessCaseAsset>)
    ];

    try {
      this.equimentList = (
        await this._assetListService.getAssetListEquipmentData({
          id: this.businessCaseId
        })
      ).equipment;
    } catch (error) {
      this._toaster.error('There was an error fetching equipment list');
    }
  }

  public submitAssetPricing(assetValues: IBusinessCaseAsset) {
    const payload: IProcuredAssetPrices = {
      id: assetValues.id,
      monthlyFee: assetValues.monthlyFee as number,
      reactiveFeeWithoutWarranty:
        assetValues.reactiveFeeWithoutWarranty as number,
      reactiveFeeWithWarranty: assetValues.reactiveFeeWithWarranty as number
    };

    try {
      this._assetListService.submitIndividualAssetPrices(
        this.businessCaseId,
        payload
      );
      this._toaster.success('Asset price submitted successfully');
    } catch {
      this._toaster.error('Error when submitting asset price');
    }
  }

  public flagAsset(assetValues: IBusinessCaseAsset) {
    const payload = {
      assetPriceStatus: 'MUST_BE_PRICED'
    };

    try {
      this._assetListService.flagIndividualAsset(assetValues.id, payload).then(async () =>
        await this._assetListService.getVerifiedAssets(
          this.businessCaseId,
        ).then(res => {
          this.dataSource.data = res.assetRetrievalList
        })
      );
      this._toast.openAlertToast({
        title: 'The asset was added for procurement to price.',
        button: false,
        config: {
          verticalPosition: 'top',
          panelClass: 'success-toast'
        }
      });
    } catch {
      this._toast.openErrorToast(
        'Error',
        'Error when submitting asset price for procurement',
        'warning',
        'error-toast',
        'right',
        'top'
      );
    }
  }

  public approveAsset(assetValues: IBusinessCaseAsset) {
    const payload = {
      assetPriceStatus: 'HAS_BEEN_PRICED'
    };

    try {
      this._assetListService.flagIndividualAsset(assetValues.id, payload).then(async () =>
        await this._assetListService.getVerifiedAssets(
          this.businessCaseId
        ).then(res => {
          this.dataSource.data = res.assetRetrievalList
        })
      );
      this._toast.openAlertToast({
        title: 'Asset price omitted from procurement',
        button: false,
        config: {
          verticalPosition: 'top',
          panelClass: 'informational-toast'
        }
      });
    } catch {
      this._toaster.error('Error when submitting asset price');
    }
  }

  public openAssetRejectionDialog(assetDetails: IBusinessCaseAsset): void {
    const dialogRef = this._dialog.open(RejectAssetPriceDialogComponent, {
      width: '40%',
      height: '78%',
      data: {
        assetDetails
      }
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: async (isAssetPriceDeclined: boolean) => {
          if (isAssetPriceDeclined) {
            const id = this.data()?.businessCase.id as number;
            this.assetsToUpdatePrice = [
              ...(await this._assetListService.getAssetsForPricing(id))
                .assetRetrievalList
            ];

            this.assetDeclined.emit();
          }
        }
      });
  }

  public openUpdateAssetPriceDialog(asset: IBusinessCaseAsset): void {
    const dialogRef = this._dialog.open(UpdateAssetPriceDialogComponent, {
      width: '50%',
      height: '80%',
      data: {
        asset,
        businessCaseId: this.businessCaseId
      }
    });
  }

  public openAssetReplacementDialog(asset: IBusinessCaseAsset): void {
    const dialogRef = this._dialog.open(AssetReplaceOverlayComponent, {
      width: '50%',
      height: '80%',
      data: {
        asset,
        businessCaseId: this.businessCaseId
      }
    });
  }

  public transformToSentenceCase(value: string | undefined): string {
    if (!value) return '';
    const formatted = value.replace(/_/g, ' ').toLowerCase();
    return formatted.charAt(0).toUpperCase() + formatted.slice(1);
  }

  public isEditor(): boolean {
    return this.businessCaseStagesConfigs.find(
      (config) => config.stageName === this.data()?.businessCase?.activeStageName
    )?.canEdit as boolean;
  }

  public isReviewer(): boolean {
    return this.businessCaseStagesConfigs.find(
      (config) => config.stageName === this.data()?.businessCase?.activeStageName
    )?.canRead as boolean;
  }

  public submitPriceArray() {

    const compiledAssetsArray: any = this.dataSource.data.map(row => ({
      id: row.id,
      monthlyFee: row.monthlyFee,
      reactiveFeeWithWarranty: row.reactiveFeeWithWarranty,
      reactiveFeeWithoutWarranty: row.reactiveFeeWithoutWarranty
    }));
    this.procurementAssetsEmitter.emit(compiledAssetsArray);
  }

  public rejectAsset(assetData: IBusinessCaseAsset) {
    const dialogRef = this._dialog.open(AssetDecliningOverlayComponent, {
      width: '450px',
      height: '350px',
      data: {
        businessCaseId: assetData.id as number
      }
    });

    dialogRef.afterClosed().subscribe({
      next: async () => {
          this._assetListService.getAssetsForPricing(
          this.businessCaseId
        ).then(res => {
          this.dataSource.data =
          res.assetRetrievalList
        })
      }
    })

  }
}
