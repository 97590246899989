import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { CommonModule, NgClass } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { OverviewTotals } from '../../interfaces/overview-totals.interface';
import { Subject, takeUntil } from 'rxjs';
import { provideNativeDateAdapter } from '@angular/material/core';
import { format, subDays } from 'date-fns';
import { BusinessCaseService } from '../../services/business-case.service';

@Component({
  selector: 'bidvest-business-cases-metrics-panel',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    MatFormFieldModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    NgClass
  ],
  templateUrl: './business-cases-metrics-panel.component.html',
  styleUrl: './business-cases-metrics-panel.component.scss',
  providers: [provideNativeDateAdapter()]
})
export class BusinessCasesMetricsPanelComponent implements OnInit, OnDestroy {
  @Input()
  public get data(): OverviewTotals {
    if (!this._data) {
      return {
        totalCases: 0,
        totalCompletedCases: 0,
        totalDelayedCases: 0,
        totalInProgressCases: 0,
        totalOverdueCases: 0
      };
    }

    return this._data;
  }

  public set data(value: OverviewTotals) {
    this._data = value;
  }

  @Output()
  public readonly dateRangeSelect: EventEmitter<{
    from: string;
    to: string | null;
  }> = new EventEmitter();

  public defaultRangeDaysCount = 7;

  public filterDateRange = new FormGroup({
    from: new FormControl(subDays(new Date(), this.defaultRangeDaysCount)),
    to: new FormControl(new Date())
  });

  private readonly _destroy$: Subject<void> = new Subject<void>();

  private _data!: OverviewTotals;
  private readonly _businessCaseService: BusinessCaseService =
    inject(BusinessCaseService);

  public ngOnInit(): void {
    this.filterDateRange.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe((entries) => {
        this.dateRangeSelect.emit({
          from: format(entries.from as Date, 'yyyy-MM-dd'),
          to: entries.to ? format(entries.to as Date, 'yyyy-MM-dd') : null
        });
      });
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public filterOverdueBusinessCases(): void {
    const filterData = { ...this._businessCaseService.defaultFilter };
    if (this.filterDateRange.valid) {
      filterData.startDate = format(
        this.filterDateRange?.value.from as Date,
        'yyyy-MM-dd'
      );
      // @ts-ignore
      filterData.endDate = format(
        this.filterDateRange?.value.to as Date,
        'yyyy-MM-dd'
      );
    }
    filterData.filters.overdue = true;
    filterData.filters.delayed = false;
    this._businessCaseService.businessCaseTableFilter.next(filterData);
  }

  public filterDelayedBusinessCases(): void {
    const filterData = { ...this._businessCaseService.defaultFilter };
    if (this.filterDateRange.valid) {
      filterData.startDate = format(
        this.filterDateRange?.value.from as Date,
        'yyyy-MM-dd'
      );
      // @ts-ignore
      filterData.endDate = format(
        this.filterDateRange?.value.to as Date,
        'yyyy-MM-dd'
      );
    }
    filterData.filters.delayed = true;
    filterData.filters.overdue = false;
    this._businessCaseService.businessCaseTableFilter.next(filterData);
  }

  public resetDateRange(): void {
    this.filterDateRange.reset({
      from: subDays(new Date(), this.defaultRangeDaysCount),
      to: new Date()
    });
  }
}
