<table mat-table [dataSource]="dataSource" appearance="outline">

  <ng-container matColumnDef="highLevelDescription">
    <th mat-header-cell *matHeaderCellDef>High level service description</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.highLevelDescription }}
    </td>
  </ng-container>

  <ng-container matColumnDef="equipmentDescription">
    <th mat-header-cell *matHeaderCellDef>Equipment description</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.equipmentDescription }}
    </td>
  </ng-container>

  <ng-container matColumnDef="assetCount">
    <th mat-header-cell *matHeaderCellDef>Asset Count</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.assetCount }}
    </td>
  </ng-container>

  <ng-container matColumnDef="mechanicalMonthlyFee">
    <th mat-header-cell *matHeaderCellDef>Sum of final Planned - Monthly</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.mechanicalMonthlyFee | roundOffByTwo }}
    </td>
  </ng-container>

  <ng-container matColumnDef="mechanicalReactiveFeeWithWarranty">
    <th mat-header-cell *matHeaderCellDef>Sum of IN WARRANTY final reactive - Monthly</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.mechanicalReactiveFeeWithWarranty | roundOffByTwo }}
    </td>
  </ng-container>

  <ng-container matColumnDef="mechanicalReactiveFeeWithoutWarranty">
    <th mat-header-cell *matHeaderCellDef>Sum of OUT OF WARRANTY final reactive - Monthly</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.mechanicalReactiveFeeWithoutWarranty | roundOffByTwo }}
    </td>
  </ng-container>

  <ng-container matColumnDef="electricalMonthlyFee">
    <th mat-header-cell *matHeaderCellDef>Sum of final Planned - Monthly</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.electricalMonthlyFee | roundOffByTwo }}
    </td>
  </ng-container>

  <ng-container matColumnDef="electricalReactiveFeeWithWarranty">
    <th mat-header-cell *matHeaderCellDef>Sum of IN WARRANTY final reactive - Monthly</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.electricalReactiveFeeWithWarranty | roundOffByTwo }}
    </td>
  </ng-container>

  <ng-container matColumnDef="electricalReactiveFeeWithoutWarranty">
    <th mat-header-cell *matHeaderCellDef>Sum of OUT OF WARRANTY final reactive - Monthly</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.electricalReactiveFeeWithoutWarranty | roundOffByTwo }}
    </td>
  </ng-container>

  <mat-paginator aria-label="Select page"> </mat-paginator>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
