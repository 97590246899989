<div class="flex flex-col">
  <div class="flex flex-row justify-between items-center p-4">
    <h4 class="font-semibold text-primary-blue text-xl">Decline Asset</h4>

    <button mat-icon-button class="text-gray-400" matDialogClose>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content class="flex flex-col mb-10">
    <h2>You are declining the provided assets pricing, please provide a reason</h2>
    <form [formGroup]="form" class="flex flex-col justify-start gap-4">
      <mat-form-field appearance="outline" class="w-full pt-4 pb-4">
        <mat-label>Reason for decline</mat-label>
        <textarea type="text" matInput placeholder="" formControlName="declineReason"></textarea>
      </mat-form-field>

      <mat-divider></mat-divider>
    </form>

    <div matDialogActions class="mt-10">
      <button type="button" mat-flat-button color="accent" (click)="submitAssetDeclineForm()" [disabled]="!form.valid"
        class="w-full">
        Submit
      </button>
    </div>
  </div>
</div>