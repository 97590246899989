<div
  class="w-full min-h-full h-screen overflow-y-auto bg-slate-200 p-4 flex flex-col justify-start"
>
  <bidvest-business-case-header>
    <span
      ngProjectAs="business-case-header-back-btn"
      [routerLink]="routes['BUSINESS_CASES']"
    >
      <button mat-icon-button>
        <mat-icon>arrow_back</mat-icon>
      </button>
    </span>
    <span
      ngProjectAs="business-case-header-title"
      [matTooltip]="businessCase?.businessCaseName!"
    >
      {{ businessCase?.businessCaseName }}
    </span>
    <span ngProjectAs="business-case-header-number" #refNo>
      {{ businessCase?.referenceNumber }}
    </span>
    <span ngProjectAs="business-case-header-actions">
      <ng-container
        *ngIf="
          businessCase?.activeStageName === 'SETUP' &&
          businessCase?.activeStageStatus === 'IN_REVIEW' &&
          isReadOnlyMode()
        "
      >
        <button
          mat-button
          color="accent"
          class="mr-4"
          (click)="showActivityLogDialog()"
        >
          <mat-icon>add</mat-icon>
          Add comment
        </button>
        <button
          mat-stroked-button
          color="accent"
          class="mr-4"
          (click)="rejectBusinessCaseCreation()"
        >
          Reject setup
        </button>
        <button
          mat-flat-button
          color="accent"
          (click)="approveBusinessCaseCreation()"
        >
          Approve setup
        </button>
      </ng-container>
    </span>
  </bidvest-business-case-header>

  <div class="rounded p-6 bg-white" *ngIf="businessCase">
    <ng-container *ngIf="businessCase.activeStageName === 'SETUP'">
      <bidvest-business-case-details-form
        [businessCaseDetails]="businessCase"
        [stageConfigs]="businessCaseStagesConfigs"
        [isReadOnly]="isReadOnlyMode()"
      ></bidvest-business-case-details-form>
    </ng-container>

    <ng-container *ngIf="businessCase.activeStageName !== 'SETUP'">
      <mat-tab-group animationDuration="0ms">
        <mat-tab label="Overview">
          <ng-template mat-tab-label>
            <h4 class="font-bold">Overview</h4>
          </ng-template>

          <div class="w-full min-h-full mt-4">
            <div class="w-full">
              <bidvest-business-case-overview-panel
                [stages]="businessCaseStagesConfigs"
                [activeStageName]="businessCase.activeStageName"
                [overview]="businessCaseOverview"
              ></bidvest-business-case-overview-panel>
            </div>

            <div class="w-full my-4">
              <mat-divider></mat-divider>
            </div>
            <div
              class="w-full grid grid-cols-12 gap-4 bg-slate-200 p-4 rounded-lg"
            >
              <div class="col-span-8 rounded p-4 bg-white">
                <bidvest-business-case-details-form
                  [businessCaseDetails]="businessCase"
                  [stageConfigs]="businessCaseStagesConfigs"
                  [isReadOnly]="isReadOnlyMode()"
                ></bidvest-business-case-details-form>
              </div>

              <div
                class="col-span-4 rounded p-4 bg-white min-h-full"
                *ngIf="businessCaseId"
              >
                <bidvest-business-case-activity-log
                  [businessCaseId]="businessCaseId"
                ></bidvest-business-case-activity-log>
              </div>
            </div>
          </div>
        </mat-tab>

        <mat-tab label="Soft services" *ngIf="includesSoftServices()">
          <ng-template mat-tab-label>
            <h4 class="font-bold">Soft services</h4>
          </ng-template>
          <div class="w-full min-h-96" *ngIf="businessCaseId">
            <bidvest-soft-services-tab
              [businessCaseId]="businessCaseId"
            ></bidvest-soft-services-tab>
          </div>
        </mat-tab>

        <mat-tab
          label="Engineering services"
          *ngIf="includesEngineeringServices()"
        >
          <ng-template mat-tab-label>
            <h4 class="font-bold">Engineering services</h4>
          </ng-template>
          <div class="w-full min-h-96" *ngIf="businessCaseId">
            <bidvest-engineering-services-overview
              *ngIf="businessCaseId"
            ></bidvest-engineering-services-overview>
          </div>
        </mat-tab>
      </mat-tab-group>
    </ng-container>
  </div>
</div>
