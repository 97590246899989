import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldModule
} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import { MatSelectModule } from '@angular/material/select';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { IBusinessCaseAsset } from '../../../interfaces/business-case-asset.interface';
import { IAssetSeen } from '../../../models/equipment-asset-seen';
import { AssetListService } from '../../../services/asset-list.service';
import { ToastrService } from 'ngx-toastr';
import { IAssetDecline } from '../../../models/asset-decline.interface';

@Component({
  selector: 'bidvest-update-asset-price-dialog',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatDialogContent,
    MatDividerModule,
    MatDialogClose,
    MatDialogActions,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule
  ],
  templateUrl: './update-asset-price-dialog.component.html',
  styleUrl: './update-asset-price-dialog.component.scss',
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { subscriptSizing: 'dynamic' }
    }
  ]
})
export class UpdateAssetPriceDialogComponent implements OnInit {
  public assetPriceUpdateForm!: FormGroup;

  public constructor(
    private _fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public readonly data: { asset: IBusinessCaseAsset; businessCaseId: number },
    private readonly _assetListService: AssetListService,
    private readonly _toastr: ToastrService,
    private readonly _dialogRef: MatDialogRef<UpdateAssetPriceDialogComponent>
  ) {}

  public ngOnInit(): void {
    this.initializeForm();
    this.prepopulateForm();
  }

  public initializeForm(): void {
    this.assetPriceUpdateForm = this._fb.group({
      assetName: [''],
      highLevelDesc: [''],
      comment: [''],
      monthlyFee: ['', Validators.required],
      monthlyReactiveFeeWithWarranty: ['', Validators.required],
      monthlyReactiveFeeWithoutWarranty: ['', Validators.required],
      quantity: ['', Validators.required],
      strategy: ['', Validators.required]
    });
  }

  public prepopulateForm(): void {
    const assetNameControl = this.assetPriceUpdateForm.get('assetName');
    assetNameControl?.patchValue(this.data.asset.discipline);
    assetNameControl?.disable();

    const highLevelDescControl = this.assetPriceUpdateForm.get('highLevelDesc');
    highLevelDescControl?.patchValue(this.data.asset.equipmentText);
    highLevelDescControl?.disable();

    const commentControl = this.assetPriceUpdateForm.get('comment');
    commentControl?.patchValue(this.data.asset.assetDecline.declineReason);
    commentControl?.disable();

    const monthlyFeeControl = this.assetPriceUpdateForm.get('monthlyFee');
    monthlyFeeControl?.patchValue(this.data.asset.monthlyFee);

    const monthlyReactiveFeeWithWarrantyControl = this.assetPriceUpdateForm.get(
      'monthlyReactiveFeeWithWarranty'
    );
    monthlyReactiveFeeWithWarrantyControl?.patchValue(
      this.data.asset.reactiveFeeWithWarranty
    );

    const monthlyReactiveFeeWithoutWarrantyControl =
      this.assetPriceUpdateForm.get('monthlyReactiveFeeWithoutWarranty');
    monthlyReactiveFeeWithoutWarrantyControl?.patchValue(
      this.data.asset.reactiveFeeWithoutWarranty
    );

    const quantityControl = this.assetPriceUpdateForm.get('quantity');
    quantityControl?.patchValue(this.data.asset.assetSeen?.quantity);

    const strategyControl = this.assetPriceUpdateForm.get('strategy');
    strategyControl?.patchValue(this.data.asset.strategy);
  }

  public async submitAssetPrice() {
    if (this.assetPriceUpdateForm.valid) {
      try {
        const payload: IBusinessCaseAsset = this.data.asset;
        const assetSeen = payload.assetSeen as IAssetSeen;
        const assetDecline = payload.assetDecline as IAssetDecline;

        const {
          monthlyFee,
          monthlyReactiveFeeWithWarranty,
          monthlyReactiveFeeWithoutWarranty,
          quantity,
          strategy
        } = this.assetPriceUpdateForm.value;

        payload.monthlyFee = monthlyFee;
        payload.reactiveFeeWithWarranty = monthlyReactiveFeeWithWarranty;
        payload.reactiveFeeWithoutWarranty = monthlyReactiveFeeWithoutWarranty;
        payload.assetSeen = assetSeen;
        payload.strategy = strategy;

        payload.assetSeen = {
          quantity,
          replacementEquipmentId: payload.assetSeen.replacementEquipmentId,
          assetSeenStatus: payload.assetSeen.assetSeenStatus
        };

        payload.assetDecline = {
          assetDeclined: false,
          declineReason: payload.assetDecline.declineReason
        };

        await this._assetListService.updateAssetPricing(
          this.data.businessCaseId,
          payload
        );

        this._dialogRef.close();
        this._toastr.success(`Asset price updated successfully`);
      } catch (error) {
        this._toastr.error(`An error occurred`);
      }
    }
  }
}
