<div class="flex flex-row justify-between items-center">
<div matDialogTitle>
  <h4 class="font-semibold text-primary-blue text-2xl m-0 p-0">
    Add staff member
  </h4>
</div>
<div class="p-4">
  <mat-icon (click)="closeStaffOverlay()" class="cursor-pointer text-gray-500">close</mat-icon>
</div>
</div>
<div matDialogContent class="overflow-y-scroll">
  <form [formGroup]="newStaffForm" class="py-8">
    <mat-form-field class="w-full" appearance="outline">
      <mat-label>Full name</mat-label>
      <input
        type="text"
        matInput
        placeholder="Full Name"
        formControlName="fullName"
      />
    </mat-form-field>

    <mat-form-field class="w-full" appearance="outline">
      <mat-label>Email</mat-label>
      <input
        type="email"
        matInput
        placeholder="Email"
        formControlName="email"
      />
    </mat-form-field>

    <mat-form-field class="w-full" appearance="outline">
      <mat-label>Password</mat-label>
      <input
        type="password"
        matInput
        placeholder="Password"
        formControlName="password"
      />
    </mat-form-field>

    <mat-form-field class="w-full" appearance="outline">
      <mat-label>Roles</mat-label>
      <mat-select matInput formControlName="role">
        @for (role of userRoles; track role) {
        <mat-option [value]="role.value">
          {{ transform(role.role) }}
        </mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-form-field class="w-full" appearance="outline">
      <mat-label>Province</mat-label>
      <mat-select matInput formControlName="province">
        <mat-option value="None"></mat-option>
        <mat-option value="All">All</mat-option>
        <mat-option value="Eastern Cape">Eastern Cape</mat-option>
        <mat-option value="Free State">Free State</mat-option>
        <mat-option value="Gauteng">Gauteng</mat-option>
        <mat-option value="KwaZulu-Natal">KwaZulu-Natal</mat-option>
        <mat-option value="Limpopo">Limpopo</mat-option>
        <mat-option value="Mpumalanga">Mpumalanga</mat-option>
        <mat-option value="Northern Cape">Northern Cape</mat-option>
        <mat-option value="North West">North West</mat-option>
        <mat-option value="Western Cape">Western Cape</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-divider></mat-divider>

    <div class="flex flex-col justify-start my-4">
      <h3 class="font-semibold text-primary-blue text-2xl mb-0">Permissions</h3>
      <small class="text-gray-400">
        Users would interact with the platform based of the permission selected
      </small>
    </div>

    <div formGroupName="permissions">
      @for ( feature of permissionFeatures; track feature) {
      <bidvest-permissions-selector
        [feature]="feature"
        [formControlName]="normalizeControlName(feature)"
      ></bidvest-permissions-selector>
      }
    </div>
  </form>
</div>
<div matDialogActions align="end" class="py-4 shadow">
  <button mat-button matDialogClose class="mr-2">Cancel</button>
  <button mat-flat-button (click)="submitForm()" color="accent">
    Submit
  </button>
</div>
