<div class="flex flex-row justify-between items-center pr-4">
  <h4 matDialogTitle>Add building number</h4>
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<div matDialogContent>
  <mat-form-field appearance="outline" floatLabel="always" class="w-full">
    <mat-label
      matTooltip="Info about the action"
      class="flex flex-row items-center"
      >Building number
      <mat-icon class="ml-2">info</mat-icon>
    </mat-label>
    <input matInput [(ngModel)]="buildingNo" (keydown.enter)="submitEntry()" />
    <mat-hint align="start">Press enter to submit</mat-hint>
  </mat-form-field>
</div>
