import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { BusinessCaseService } from '../../services/business-case.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'bidvest-pdf-drawing-view',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatPaginatorModule,
    MatMenuModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatCheckboxModule,
    MatTableModule,
    PdfViewerModule,
    FormsModule
  ],
  templateUrl: './pdf-drawing-view.component.html',
  styleUrl: './pdf-drawing-view.component.scss'
})
export class PdfDrawingViewComponent {

  public isPanning = false;
  public startX = 0;
  public startY = 0;
  public scrollLeft = 0;
  public offsetX = 0;
  public offsetY = 0;
  public scrollTop = 0;

  public zoomValue: number = 0.8;
  public pdfDrawing: string = '';
  public drawingId: number = 0;
  public drawingName: string = '';

  public constructor(
    public businessCaseService: BusinessCaseService,
    private readonly _route: ActivatedRoute = inject(ActivatedRoute),
    private readonly _loader: NgxUiLoaderService,
    private readonly _toast: ToastrService

  ) {
    this.getBusinessCaseDrawing();
  }

  public startPanning(event: MouseEvent) {
    this.isPanning = true;

    // Save the initial scroll and mouse positions
    this.startX = event.clientX - this.offsetX;
    this.startY = event.clientY - this.offsetY;

    (event.target as HTMLElement).classList.add('cursor-grabbing'); // Change the cursor to grabbing
  }

  // Triggered on mousemove
  public panImage(event: MouseEvent) {
    if (!this.isPanning) return;

    // Calculate the new scroll position
    this.offsetX = event.clientX - this.startX;
    this.offsetY = event.clientY - this.startY;
  }

  // Triggered on mouseup or mouseleave
  public endPanning() {
    this.isPanning = false;
    document.querySelector('.cursor-grabbing')?.classList.replace('cursor-grabbing', 'cursor-grab'); // Reset the cursor
  }

  public zoomIn() {
    let increaseZoom = this.zoomValue + 0.1;
    this.zoomValue = increaseZoom;
  }

  public zoomOut() {
    let decreaseZoom = this.zoomValue - 0.1;
    this.zoomValue = decreaseZoom;
  }

  public async getBusinessCaseDrawing() {
    this._loader.start();
    const routeParams: ParamMap = await firstValueFrom(this._route.paramMap);
    const businessCaseId = routeParams.get('id') as unknown as number;
    const drawingArray = (await firstValueFrom(this.businessCaseService.fetchBusinessCaseById(businessCaseId))).documents;
    const drawingId = drawingArray[0]?.id;
    const drawingName = drawingArray[0]?.documentUrl.replace('business-cases/', '')
    try {
      const drawingBlobFormat = await firstValueFrom(this.businessCaseService.fetchDoc(businessCaseId, drawingId, drawingName));
      this.pdfDrawing = await this.convertBlobToBase64(drawingBlobFormat);
      this._loader.stop();
    } catch (error) {
      this._toast.error(`An error occurred: ${error}`, 'Error alert');

    }
  }

  private convertBlobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

}
