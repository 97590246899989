<mat-form-field class="w-full">
  <input
    type="text"
    matInput
    [formControl]="searcInputControl"
    [matAutocomplete]="auto"
    placeholder="Search by equipment ID"
  />
  <mat-autocomplete #auto="matAutocomplete">
    <mat-option [value]="filteredOptions">
      {{ filteredOptions | async }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
