<div class="flex flex-row justify-between items-center p-4">
  <h4 class="text-primary-blue font-bold text-xl">
    Reason For Rejecting Quote
  </h4>

  <button mat-icon-button matDialogClose>
    <mat-icon>close</mat-icon>
  </button>
</div>
<div mat-dialog-content>
  <span class="text-sm pb-4"
    >You are rejecting the rate for the asset below</span
  >
  <form class="mt-4" [formGroup]="assetRejectionForm">
    <mat-form-field class="w-full mb-4" appearance="outline">
      <mat-label>SAP ID</mat-label>
      <input type="text" matInput formControlName="sapId" />
    </mat-form-field>

    <mat-form-field class="w-full mb-4" appearance="outline">
      <mat-label>SAP Name</mat-label>
      <input type="text" matInput formControlName="sapName" />
    </mat-form-field>

    <mat-divider></mat-divider>

    <mat-form-field class="w-full mb-4" appearance="outline">
      <mat-label>Reason for rejecting quote</mat-label>
      <textarea
        matInput
        rows="8"
        formControlName="reasonForRejection"
      ></textarea>
    </mat-form-field>

    <button
      mat-flat-button
      color="accent"
      class="w-full"
      [disabled]="!assetRejectionForm.valid"
      (click)="submitAssetRejection()"
    >
      submit
    </button>
  </form>
</div>
