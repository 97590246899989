<div class="flex flex-col h-[120vh]">
  <div
    #container
    class="h-[120vh] overflow-hidden border-[1px] rounded-lg"
    (mousedown)="startPanning($event)"
    (mousemove)="panImage($event)"
    (mouseup)="endPanning()"
    (mouseleave)="endPanning()"
  >
    <pdf-viewer
      [src]="pdfDrawing"
      [render-text]="false"
      [original-size]="true"
      [show-borders]="false"
      style="
        height: 120vh;
        width: 100%;
        background-color: #FFFFFF;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
      "
      [style.transform]="'translate(' + offsetX + 'px, ' + offsetY + 'px)'"
      [zoom]="zoomValue"
      [zoom-scale]="'page-fit'"
    >
    </pdf-viewer>
  </div>
  <div
    class="flex flex-row justify-center items-end w-full relative bottom-14 pb-4 gap-2"
  >
    <button
      class="bg-slate-500 justify-center items-center flex text-2xl"
      (click)="zoomIn()"
      mat-stroked-button
    >
      <mat-icon>zoom_in</mat-icon>
    </button>
    <button class="bg-slate-500" (click)="zoomOut()" mat-stroked-button>
      <mat-icon>zoom_out</mat-icon>
    </button>
  </div>
</div>
