<form [formGroup]="collaboratorsForm" class="grid grid-cols-12 gap-4 mt-8">
  <div class="col-span-4 flex flex-col justify-center">
    <h4 class="font-bold">
      {{ collaboratorsData().businessCaseStageName.split('_').join(' ') }}
    </h4>
  </div>

  <mat-form-field
    appearance="outline"
    floatLabel="always"
    class="col-span-4 w-full mr-4"
  >
    <mat-label>Stage Owner</mat-label>
    <mat-select
      formControlName="owner"
      name="owner"
      [(value)]="collaboratorsData().ownerId"
      (selectionChange)="updateReviewerFilter($event)"
    >
      @for (collaborator of accounts | filterBy: ['id']: availableStageOwnerIds;
      track collaborator) {
      <mat-option [value]="collaborator.id">{{
        collaborator.fullName
      }}</mat-option>
      }
    </mat-select>
  </mat-form-field>

  <mat-form-field
    appearance="outline"
    floatLabel="always"
    class="col-span-4 w-full mr-4"
  >
    <mat-label>Reviewers</mat-label>
    <mat-select
      formControlName="reviewers"
      [(value)]="collaboratorsData().reviewerIds"
      (selectionChange)="updateStageOwnerFilter($event)"
      multiple
    >
      @for (collaborator of accounts | filterBy: ['id']: availableReviewerIds;
      track collaborator) {
      <mat-option [value]="collaborator.id">{{
        collaborator.fullName
      }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
</form>
