import { CommonModule, NgClass } from '@angular/common';
import {
  Component,
  effect,
  inject,
  Injector,
  Input,
  input,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { AssetListService } from '../../../services/asset-list.service';
import { MatDialog } from '@angular/material/dialog';
import { AssetListSeenOverlayComponent } from '../../overlays/asset-list-seen/asset-list-seen-overlay.component';
import { AssetListUnseenOverlayComponent } from '../../overlays/asset-list-unseen/asset-list-unseen-overlay.component';
import { IEquipmentRetrievalModel } from '../../../models/asset-list-equipment';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { AssetDisciplinesEnum } from '../../../enums/asset-disciplines.enum';
import { IBusinessCaseDetails } from '../../../interfaces/business-case-details.interface';
import { PdfDrawingViewComponent } from '../../pdf-drawing-view/pdf-drawing-view.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { GlobalPositionStrategy, Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { BusinessCaseActivityLogComponent } from '../../business-case-activity-log/business-case-activity-log.component';

@Component({
  selector: 'bidvest-verification-table',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatIconModule,
    MatPaginatorModule,
    MatMenuModule,
    MatSelectModule,
    MatTableModule,
    FormsModule,
    PdfDrawingViewComponent,
    NgClass
  ],
  templateUrl: './verify-asset-table.component.html',
  styleUrl: './verify-asset-table.component.scss'
})
export class VerifyAssetTableComponent implements OnInit {
  @ViewChild(MatPaginator) public paginator!: MatPaginator;

  public filterOptions = Object.values(AssetDisciplinesEnum);
  public businessCaseId!: number;
  public searchTerm: string = '';
  public filterValues: string = '';
  public businessCase: IBusinessCaseDetails | null = null;
  @Input()
  public isEditor = false;
  @Input()
  public isReviewer = false;
  public dataSource = new MatTableDataSource<IEquipmentRetrievalModel>([]);

  public displayedColumns: string[] = [
    'discipline',
    'equipmentText',
    'equipmentId',
    'strategy',
    'implementationDate',
    'status',
    'actions'
  ];
  public showTable: boolean = true;

  constructor(
    private _assetListService: AssetListService,
    private readonly _overlay: Overlay,
    private readonly _dialog: MatDialog,
    private readonly _injector: Injector,
    public assetListService: AssetListService,
    private readonly _loader: NgxUiLoaderService,
    private readonly _route: ActivatedRoute
  ) {}

  public async ngOnInit(): Promise<void> {
    this.dataSource.paginator = this.paginator;

    const routeParams: ParamMap = await firstValueFrom(this._route.paramMap);
    this.businessCaseId = routeParams.get('id') as unknown as number;
    await this.initializeData();

    if (this.isEditor) {
      this.hideTableColumn('status');
    } else {
      this.hideTableColumn('actions');
    }
  }

  public async markAssetAsSeen(data: IEquipmentRetrievalModel): Promise<void> {
    const dialogRef = this._dialog.open(AssetListSeenOverlayComponent, {
      width: '550px',
      height: '520px',
      data: { data, businessCaseId: this.businessCaseId }
    });

    dialogRef.afterClosed().subscribe({
      next: async () => {
        await this.initializeData();
      }
    });
  }

  public async markAssetAsUnseen(
    data: IEquipmentRetrievalModel
  ): Promise<void> {
    const dialogRef = this._dialog.open(AssetListUnseenOverlayComponent, {
      width: '550px',
      height: '560px',
      data: { data, businessCaseId: this.businessCaseId }
    });

    dialogRef.afterClosed().subscribe({
      next: async () => {
        await this.initializeData();
      }
    });
  }

  public async initializeData(): Promise<void> {
    const routeParams: ParamMap = await firstValueFrom(this._route.paramMap);
    const businessCaseId = routeParams.get('id') as unknown as number;
    const equipment = await this.assetListService.getAssetListEquipmentData({
      id: businessCaseId,
      page: 0, // this.paginator.pageIndex,
      size: 100,
      filter: this.filterValues,
      searchTerm: this.searchTerm
    });
    this.dataSource = new MatTableDataSource<IEquipmentRetrievalModel>(
      equipment.equipment
    );
  }
  public async searchOnClick() {
    await this.initializeData();
  }
  public async applyFilter(event: string) {
    this.filterValues = event;
    await this.initializeData();
  }

  public hideTableColumn(columnName: string): void {
    const index = this.displayedColumns.indexOf(columnName);
    if (index !== -1) {
      this.displayedColumns.splice(index, 1);
    }
  }
  public async showAssetListTable() {
    this.showTable = !this.showTable;
  }
  public showActivityLogDialog(): void {
    const overlayRef = this._overlay.create({
      width: '450px',
      height: '600px',
      backdropClass: 'bg-slate-400/50',
      panelClass: ['overflow-y-auto', 'rounded-lg'],
      hasBackdrop: true,
      positionStrategy: new GlobalPositionStrategy()
        .bottom('20px')
        .right('15px'),
      scrollStrategy: this._overlay.scrollStrategies.block()
    });
    const activityLogPortal = new ComponentPortal(
      BusinessCaseActivityLogComponent
    );
    const componentRef = overlayRef.attach(activityLogPortal);

    componentRef.setInput('businessCaseId', this.businessCaseId as number);
    componentRef.instance.overlayRef = overlayRef;
  }
}
