import { Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  MatDialogClose,
  MatDialogContent,
  MatDialogRef
} from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { NewBusinessCaseFormComponent } from '../new-business-case-form/new-business-case-form.component';
import { BusinessCaseHeaderComponent } from '@bidvest/shared';
import { BusinessCaseService } from '../../services/business-case.service';
import { firstValueFrom, Subject } from 'rxjs';
import {
  BusinessCaseSetup,
  BusinessCaseStage
} from '../../interfaces/business-case-setup.interface';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import {
  Collaborator,
  UpdateBusinessCasePayload
} from '../../interfaces/update-business-case-payload.interface';
import { ToastrService } from 'ngx-toastr';
import { IBusinessCaseDetails } from '../../interfaces/business-case-details.interface';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'bidvest-create-business-case-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    MatTooltipModule,
    MatDialogClose,
    MatDialogContent,
    MatSelectModule,
    NewBusinessCaseFormComponent,
    BusinessCaseHeaderComponent,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule
  ],
  templateUrl: './create-business-case-dialog.component.html',
  styleUrl: './create-business-case-dialog.component.scss'
})
export class CreateBusinessCaseDialogComponent implements OnInit, OnDestroy {
  @ViewChild('newBusinessCase')
  public newBusinessCase!: NewBusinessCaseFormComponent;
  public businessCaseSetup!: BusinessCaseSetup;
  public businessCaseStages: Array<BusinessCaseStage> = [];
  public businessCaseDetails!: IBusinessCaseDetails;
  public businessCaseTypes: Array<string> = [
    'ENGINEERING_AND_SOFT_SERVICES',
    'ATM_SINGLE_BC',
    'ATM_GROUP_BC',
    'SOFT_SERVICES',
    'ENGINEERING_SERVICES',
    'CATERING'
  ];
  public businessCaseTypeForm!: FormGroup;
  public businessCaseSubTypeForm!: FormGroup;
  private readonly _destroy$: Subject<void> = new Subject<void>();

  public constructor(
    private readonly _businessCaseService: BusinessCaseService,
    private readonly _fb: FormBuilder,
    private readonly _dialogRef: MatDialogRef<CreateBusinessCaseDialogComponent>,
    private readonly _toastr: ToastrService,
    private readonly _loader: NgxUiLoaderService ) {
  }
  public ngOnInit(): void {
    this.businessCaseTypeForm = this._fb.group({
      businessCaseType: ['', Validators.required]
    });

    this.businessCaseSubTypeForm = this._fb.group({
      businessCaseType: ['', Validators.required]
    });
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public async initializeBusinessCase(): Promise<void> {
    try {
      if (this.businessCaseTypeForm.valid) {
        const { businessCaseType } = this.businessCaseTypeForm.getRawValue();

        await this._businessCaseService.initializeBusinessCase(
          businessCaseType
        );
        this.businessCaseSetup = this._businessCaseService.businessCaseSetup;
        this.businessCaseStages = this.businessCaseSetup.stages;

        this.businessCaseDetails = await firstValueFrom(
          this._businessCaseService.fetchBusinessCaseById(
            this.businessCaseSetup.id
          )
        );
      }
    } catch (error) {}
  }

  public async submitBusinessCaseForReview(): Promise<void> {
    if (this.validateBusinessCaseEntries()) {
      const {
        businessCaseActionType,
        name,
        implementationDate,
        description,
        town,
        province,
        buildingNumber,
        outletType,
        outlet
      } = this.newBusinessCase.businessCaseForm.getRawValue();

      this._loader.start();

      const payload: UpdateBusinessCasePayload = {
        id: this.businessCaseSetup.id,
        referenceNumber: this.businessCaseSetup.referenceNumber,
        businessCaseName: name,
        buildingNumber,
        implementationDate,
        businessCaseActionType,
        writeUp: '',
        town,
        province,
        outlet,
        outletType,
        collaborators: [...this.newBusinessCase.selectedCollaborators],
        businessCaseDescription: description
      };

      try {
        await firstValueFrom(
          this._businessCaseService.updateBusinessCase(payload)
        );

        await firstValueFrom(
          this._businessCaseService.submitBusinessCaseForReview({
            businessCaseId: this.businessCaseDetails.id,
            businessCaseStageId: this.businessCaseDetails.activeStageId
          })
        );

        this._loader.stop();

        this._toastr.success(
          `Business Case ${this.businessCaseSetup.referenceNumber} has been submitted for review`
        );
        this._businessCaseService.businessCaseTableFilter.next(
          this._businessCaseService.defaultFilter
        );
        this._dialogRef.close();
      } catch (error) {
        this._loader.stop();
        this._toastr.error(`An error occurred`);
        this._dialogRef.close();
      }
    }
  }

  public validateBusinessCaseEntries(): boolean {
    return (this.newBusinessCase?.businessCaseForm.valid && this.newBusinessCase.requiredDocs.cdaLayout && this.newBusinessCase.requiredDocs.iopSchedule && this.newBusinessCase.requiredDocs.rr03PropertyList);
  }
}
