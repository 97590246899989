import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EngineeringServicesTabComponent } from '../../components/tables/engineering-services-overview-tab/engineering-services-overview-tab.component';

@Component({
  selector: 'bidvest-engineering-services-overview',
  standalone: true,
  imports: [CommonModule, EngineeringServicesTabComponent],
  templateUrl: './engineering-services-overview.component.html',
  styleUrl: './engineering-services-overview.component.scss'
})
export class EngineeringServicesOverviewComponent {}
