<div class="w-full h-[100vh] bg-slate-200 p-4 flex flex-col justify-start overflow-y-auto">
  <bidvest-business-case-header>
    <span ngProjectAs="business-case-header-back-btn">
      <button mat-icon-button [routerLink]="routes['BUSINESS_CASES'] + '/' + businessCaseId">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </span>
    <span ngProjectAs="business-case-header-title">
      <p class="font-bold text-primary-blue">
        {{
        businessCase.businessCaseName
        ? businessCase.businessCaseName
        : 'Not available'
        }}
      </p>
    </span>
    <span ngProjectAs="business-case-header-number" #refNo>{{
      businessCase.referenceNumber
      ? businessCase.referenceNumber
      : 'Not available'
      }}</span>
    <span ngProjectAs="business-case-header-actions">
      @if (businessCase.activeStageName === 'PROCUREMENT_TO_PRICE' &&
      businessCase.activeStageStatus === 'DRAFT'
       && isEditor ||
      businessCase.activeStageName === 'PROCUREMENT_TO_PRICE' &&
      businessCase.activeStageStatus === 'REJECTED'
       && isEditor){
      <button mat-flat-button color="accent" (click)="submitUpdatedPricesForReview()">
        Submit Updated Prices For Review
      </button>}
      
      @if (businessCase.activeStageName === 'PROCUREMENT_TO_PRICE' &&
      (businessCase.activeStageStatus === 'IN_REVIEW') && isReviewer){
      <button mat-stroked-button color="accent" class="mr-4 mb-1" (click)="rejectUpdatedPrices()">
        Reject updated prices
      </button>
      <button mat-flat-button color="accent" (click)="approveUpdatedPrices()">
        Approve updated prices</button>}
    </span>
  </bidvest-business-case-header>

  <div class="flex flex-col bg-white min-h-[100vh]">
    <div class="w-full p-8 border-b-2 border-solid">
      <h4 class="font-bold text-primary-blue text-2xl">Procurement</h4>
      <p>Update asset pricing</p>
    </div>

    <div class="mt-4 bg-white rounded px-8 pb-8 flex flex-col justify-start">
      <div class="flex flex-row justify-between items-center">
        <div class="flex flex-row justify-start items-center">
          <mat-form-field appearance="outline" class="w-72 mr-4">
            <mat-label>Search</mat-label>
            <input matInput [(ngModel)]="searchInput" (keyup.enter)="searchAssets()" />
            <button mat-icon-button matSuffix (click)="searchAssets()" [attr.aria-label]="'Search'">
              <mat-icon>search</mat-icon>
            </button>
          </mat-form-field>
        </div>

        <div class="flex flex-row justify-start items-center">
          <button class="mr-4" mat-button color="accent" (click)="showActivityLogDialog()">
            <mat-icon>add</mat-icon>
            Add comment
          </button>
        </div>
      </div>
      <bidvest-asset-pricing-table (procurementAssetsEmitter)="handleAssetData($event)" [data]="{
          assets: assetsForPricing,
          businessCase: this.businessCase
        }"></bidvest-asset-pricing-table>
    </div>
  </div>
</div>