import { CommonModule } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  Inject
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldModule
} from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { IBusinessCaseAsset } from '../../../interfaces/business-case-asset.interface';
import { AssetListService } from '../../../services/asset-list.service';
import { IDeclineAsset } from '../../../interfaces/deline-asset.interface';

@Component({
  selector: 'bidvest-asset-declining-overlay',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatDatepickerModule,
    MatDividerModule,
    MatDialogClose,
    MatDividerModule,
    MatDialogContent,
    MatDialogActions
  ],
  templateUrl: './asset-declining-overlay.component.html',
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { subscriptSizing: 'dynamic' }
    }
  ],
  styleUrl: './asset-declining-overlay.component.scss'
})
export class AssetDecliningOverlayComponent implements OnInit {
  public form!: FormGroup;

  public constructor(
    private readonly _fb: FormBuilder,
    private readonly _loader: NgxUiLoaderService,
    private readonly _toast: ToastrService,
    @Inject(MAT_DIALOG_DATA)
    public readonly data: { asset: IBusinessCaseAsset; businessCaseId: number },
    private readonly _assetListService: AssetListService,
    private readonly _toastr: ToastrService,
    private readonly _dialogRef: MatDialogRef<AssetDecliningOverlayComponent>
  ) { }

  public ngOnInit(): void {
    this.initializeForm();
  }


  private initializeForm(): void {
    this.form = this._fb.group({
      declineReason: ['', [Validators.required]],
    });
  }

  public async submitAssetDeclineForm(): Promise<void> {
    if (this.form.valid) {
      const payload: IDeclineAsset = {
        assetDeclined: true,
        declineReason: this.form.value.declineReason
      }
      try {
        const id = this.data.businessCaseId;
        await this._assetListService.declineAsset(id, payload);

        this._dialogRef.close();

        this._toastr.success('Asset price has been declined successfully');
      } catch (error) {
        this._toastr.error('Error when declining asset price');
       }
    }
  }
}
