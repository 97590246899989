import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BusinessCaseService } from '../../../services/business-case.service';
import { BuildingData } from '../../../interfaces/building-data.interface';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'bidvest-building-number-input-dialog',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogContent,
    MatDialogTitle,
    MatDialogClose,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule
  ],
  templateUrl: './building-number-input-dialog.component.html',
  styleUrl: './building-number-input-dialog.component.scss'
})
export class BuildingNumberInputDialogComponent implements OnInit {
  public buildingNo: string = '';
  public buildings: Array<BuildingData> = [];

  constructor(
    private readonly _dialogRef: MatDialogRef<BuildingNumberInputDialogComponent>,
    private readonly _bcService: BusinessCaseService
  ) {}

  public async ngOnInit(): Promise<void> {
    try {
      this.buildings = await firstValueFrom(
        this._bcService.fetchBuildingList()
      );
    } catch (error) {}
  }

  public submitEntry(): void {
    if (this.buildingNo.length > 0) {
      const buildingNoExists: boolean = this.buildings
        .map((building) => building.ONEVIEW)
        .includes(this.buildingNo);

      if (buildingNoExists) {
        const data: BuildingData = this.buildings.find(
          (building) => building.ONEVIEW === this.buildingNo
        ) as BuildingData;

        this._dialogRef.close(data);
      } else {
        this._dialogRef.close();
      }
    }
  }
}
