import {
  Component,
  inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { BusinessCaseStage } from '../../interfaces/business-case-setup.interface';
import { MatRadioModule } from '@angular/material/radio';
import { BusinessCaseService } from '../../services/business-case.service';
import { IBusinessCaseOverview } from '../../interfaces/business-case-overview.interface';
import { UserAvatarComponent } from '@bidvest/shared';
import { BusinessCaseStageIndicatorComponent } from '../business-case-stage-indicator/business-case-stage-indicator.component';
import { IBusinessCaseStageConfig } from '../../interfaces/business-case-stage-config.interface';
import { IBusinessCaseDetails } from '../../interfaces/business-case-details.interface';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'bidvest-business-case-overview-panel',
  standalone: true,
  imports: [
    CommonModule,
    MatDividerModule,
    MatRadioModule,
    UserAvatarComponent,
    BusinessCaseStageIndicatorComponent
  ],
  templateUrl: './business-case-overview-panel.component.html',
  styleUrl: './business-case-overview-panel.component.scss'
})
export class BusinessCaseOverviewPanelComponent implements OnInit {
  @Input()
  public stages: Array<IBusinessCaseStageConfig> = [];

  @Input()
  public overview: IBusinessCaseOverview | null = null;

  @Input()
  public get activeStageName(): string {
    return this._activeStageName;
  }

  public set activeStageName(value: string | undefined) {
    if (value) {
      this._activeStageName = value;
    }
  }

  public businessCaseId!: number;
  public businessCase: IBusinessCaseDetails | undefined;
  public statusConfig: string = '';

  private _activeStageName!: string;

  constructor(
    private readonly _loader: NgxUiLoaderService,
    private readonly _route: ActivatedRoute,
    private readonly _toastr: ToastrService,
    private readonly _businessCaseService: BusinessCaseService
  ) {}

  public async ngOnInit(): Promise<void> {
    this._loader.start();
    try {
      const routeParams: ParamMap = await firstValueFrom(this._route.paramMap);
      this.businessCaseId = routeParams.get('id') as unknown as number;

      this.businessCase = await firstValueFrom(
        this._businessCaseService.fetchBusinessCaseById(this.businessCaseId)
      );
      this.statusClassConfig();

      this._loader.stop();
    } catch (error) {
      this._toastr.error(
        `There was as issue loading the business case details with id ${this.businessCaseId}`
      );
    }
  }

  public isActiveStage(stage: string): boolean {
    return this._activeStageName === stage;
  }

  public getStageOwnerAbbreviatedName(): string {
    return this.overview?.activeStageOwner
      .split(' ')[0]
      .charAt(0)
      .concat(this.overview.activeStageOwner.split(' ')[1].charAt(0))
      .toUpperCase() as string;
  }

  public includesEngineeringServices(): boolean {
    return this._businessCaseService.includesEngineeringServices(
      this.businessCase as IBusinessCaseDetails
    );
  }

  public includesSoftServices(): boolean {
    return this._businessCaseService.includesSoftServices(
      this.businessCase as IBusinessCaseDetails
    );
  }

  public transformToSentenceCase(value: string | undefined): string {
    if (!value) return '';
    const formatted = value.replace(/_/g, ' ').toLowerCase();
    return formatted.charAt(0).toUpperCase() + formatted.slice(1);
  }

  public statusClassConfig() {
    if (this.businessCase?.activeStageStatus === 'IN_REVIEW') {
      this.statusConfig = 'bg-orange-500';
    } else if (this.businessCase?.activeStageStatus === 'DRAFT') {
      this.statusConfig = 'bg-[#8B8A93]';
    } else if (this.businessCase?.activeStageStatus === 'REJECTED') {
      this.statusConfig = 'bg-[#B80000]';
    }
  }
}
