import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { firstValueFrom } from 'rxjs';
import { IBusinessCaseOverallGmpImpactViewModel } from '../models/business-case-overall-gmp-impact-view-model';
import { IEngineeringQuote } from '../interfaces/engineering-quote.interface';

@Injectable({
  providedIn: 'root'
})
export class EngineeringServiceTableService {
  private baseUrl: string = environment.apiUrl;

  constructor(private readonly _http: HttpClient) {}

  public async getGmpOverallImpact(
    businessCaseId: number
  ): Promise<IBusinessCaseOverallGmpImpactViewModel[]> {
    return firstValueFrom(
      this._http.get<IBusinessCaseOverallGmpImpactViewModel[]>(
        `${this.baseUrl}/business-cases/${businessCaseId}/engineering-gmp-impacts`
      )
    );
  }

  public async getEngineeringQuotes(
    businessCaseId: number
  ): Promise<IEngineeringQuote> {
    return firstValueFrom(
      this._http.get<IEngineeringQuote>(
        `${this.baseUrl}/business-cases/${businessCaseId}/engineering-quotes`
      )
    );
  }
}
