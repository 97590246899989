import { Component, Inject, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { BusinessCaseService } from '../../../services/business-case.service';
import { UserBusinessCase } from '../../../interfaces/user-business-case.interface';
import { groupBy } from 'lodash';
import { IBusinessCaseAccount } from '../../../interfaces/collaborator.interface';
import { firstValueFrom } from 'rxjs';
import { UpdateUserBusinessCasesPayload } from '../../../interfaces/update-user-business-cases-payload.interface';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'bidvest-deactivate-user-dialog',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatSelectModule,
    MatDividerModule,
    MatSelectModule,
    MatDialogActions,
    MatDialogContent,
    MatButtonModule,
    MatInputModule,
    MatDialogClose
  ],
  templateUrl: './deactivate-user-dialog.component.html',
  styleUrl: './deactivate-user-dialog.component.scss'
})
export class DeactivateUserDialogComponent implements OnInit {
  public deactivateUserForm!: FormGroup;
  public userBusinessCases!: Array<UserBusinessCase>;
  public bcGroups!: { [name: string]: Array<UserBusinessCase> };
  public bcGroupKeys!: Array<string>;
  public selectedUsers: Set<UpdateUserBusinessCasesPayload> = new Set();
  public totalReplacementsRequired: number = 0;

  private _bcAccounts: Array<IBusinessCaseAccount> = [];

  public constructor(
    @Inject(MAT_DIALOG_DATA)
    public readonly data: { userId: number; name: string },
    private readonly _fb: FormBuilder,
    private readonly _businessCaseService: BusinessCaseService,
    private readonly _toastr: ToastrService,
    private readonly _dialogRef: MatDialogRef<DeactivateUserDialogComponent>
  ) {}

  public async ngOnInit(): Promise<void> {
    try {
      this.deactivateUserForm = this._fb.group({});
      this.userBusinessCases =
        await this._businessCaseService.fetchUserBusinessCases(
          this.data.userId
        );
      this.bcGroups = groupBy(this.userBusinessCases, 'businessCaseName');
      this.bcGroupKeys = Object.keys(this.bcGroups);

      this._bcAccounts = await firstValueFrom(
        this._businessCaseService.fetchBusinessCaseAccounts()
      );

      this.totalReplacementsRequired = Object.values(this.bcGroups)
        .map((val) => val.length)
        .reduce((a, b) => a + b);
    } catch (error) {
      console.log(error);
    }
  }

  public getAvailableAccounts(userId: number): Array<IBusinessCaseAccount> {
    return this._bcAccounts.filter((account) => account.id !== userId);
  }

  public getSelectedAccount(
    event: MatSelectChange,
    otherArgs: { owner: boolean; bc: UserBusinessCase }
  ) {
    if (otherArgs.owner) {
      this.selectedUsers.add({
        businessCaseId: otherArgs.bc.businessCaseId,
        businessCaseStageId: otherArgs.bc.businessCaseStageId,
        ownerId: event.value,
        reviewerIdToAdd: null,
        reviewerIdToRemove: null
      });
    } else {
      this.selectedUsers.add({
        businessCaseId: otherArgs.bc.businessCaseId,
        businessCaseStageId: otherArgs.bc.businessCaseStageId,
        ownerId: otherArgs.bc.ownerId,
        reviewerIdToAdd: event.value,
        reviewerIdToRemove: this.data.userId
      });
    }
  }

  public async submitForm(): Promise<void> {
    try {
      const selectedUsers = Array.from(this.selectedUsers);
      const userRolesReplaced: boolean =
        this.totalReplacementsRequired === selectedUsers.length;

      await this._businessCaseService.updateUserBusinessCases(
        this.data.userId,
        selectedUsers
      );
      this._dialogRef.close(userRolesReplaced);
      this._toastr.success('Business cases have been updated!');
    } catch (error) {}
  }
}
