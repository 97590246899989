<table mat-table [dataSource]="dataSource" appearance="outline">

  <ng-container matColumnDef="title">
    <th mat-header-cell *matHeaderCellDef>Category</th>
    <td mat-cell class="category-text" *matCellDef="let element">
      <div class="title-container">
        <div>
          <span>{{ element.title }}</span>
        </div>
        <div>
          <p class="in-warranty" *ngIf="element.gmpOverallImpactType === GmpOverallImpactTypeEnum.IN_WARRANTY">
            In warranty
          </p>
          <p class="out-warranty" *ngIf="element.gmpOverallImpactType === GmpOverallImpactTypeEnum.OUT_WARRANTY">
            Out of warranty
          </p>
        </div>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="gmpBeforeChange">
    <th mat-header-cell *matHeaderCellDef>GMP Before change</th>
    <td mat-cell class="beforeCell" *matCellDef="let element">R{{ element.gmpBeforeChange | roundOffByTwo }}</td>
  </ng-container>

  <ng-container matColumnDef="removedAssetNotReplaced">
    <th mat-header-cell *matHeaderCellDef>Removed asset not replaced</th>
    <td mat-cell class="removedCell" *matCellDef="let element">
      R{{ element.removedAssetNotReplaced | roundOffByTwo }}</td>
  </ng-container>

  <ng-container matColumnDef="revisedGMPBeforeNewAsset">
    <th mat-header-cell *matHeaderCellDef>Revised GMP before first time new assets</th>
    <td mat-cell *matCellDef="let element">R{{ element.revisedGMPBeforeNewAsset | roundOffByTwo }}</td>
  </ng-container>

  <ng-container matColumnDef="firstReactivePercentage">
    <th mat-header-cell *matHeaderCellDef>% Reactive</th>
    <td mat-cell *matCellDef="let element">
      <p *ngIf="element.gmpOverallImpactType !== GmpOverallImpactTypeEnum.PLANNED">
        %{{ element.firstReactivePercentage | roundOffByTwo }}
      </p>
    </td>
  </ng-container>



  <ng-container matColumnDef="finalRevisedGmp">
    <th mat-header-cell *matHeaderCellDef>Final Revised GMP</th>
    <td mat-cell class="beforeCell" *matCellDef="let element">R{{ element.finalRevisedGmp | roundOffByTwo }}</td>
  </ng-container>

  <ng-container matColumnDef="gmpNetImpact">
    <th mat-header-cell *matHeaderCellDef>GMP NET Impact</th>
    <td mat-cell class="removedCell" *matCellDef="let element">R{{ element.gmpNetImpact | roundOffByTwo }}</td>
  </ng-container>

  <mat-paginator aria-label="Select page"></mat-paginator>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
