<div class="flex flex-row justify-between p-4">
  <p class="text-primary-blue font-bold text-xl">Asset not seen</p>
  <mat-icon (click)="closeAssetList()" class="text-gray-500 cursor-pointer"
    >close</mat-icon
  >
</div>
<div matDialogContent>
  <form [formGroup]="form" class="flex flex-col gap-4">
    <mat-form-field class="w-full" appearance="outline">
      <mat-label>Equipment ID</mat-label>
      <input type="text" matInput formControlName="equipmentId" />
    </mat-form-field>
    <mat-form-field class="w-full" appearance="outline">
      <mat-label>Discipline</mat-label>
      <input type="text" matInput formControlName="discipline" />
    </mat-form-field>
    <mat-form-field class="w-full" appearance="outline">
      <mat-label>Description</mat-label>
      <input type="text" matInput formControlName="description" />
    </mat-form-field>
    <div class="my-6">
      <mat-divider></mat-divider>
    </div>
    <mat-form-field class="w-full" appearance="outline">
      <mat-label>Notes</mat-label>
      <textarea type="text" matInput formControlName="note"></textarea>
    </mat-form-field>
  </form>
</div>
<div matDialogActions>
  <button
    mat-flat-button
    color="accent"
    [disabled]="!form.valid"
    (click)="rejectAsset()"
    class="w-full m-4"
  >
    Submit
  </button>
</div>
