<div class="bg-gray-100 rounded p-4 min-h-12 flex flex-col justify-start">
  <div class="w-full flex flex-row justify-evenly gap-2">
    <span class="w-[30%] rounded px-4 py-2 flex flex-col justify-center">
      <mat-form-field>
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input
          [formGroup]="filterDateRange"
          [rangePicker]="picker"
        >
          <input matStartDate formControlName="from" placeholder="Start date" />
          <input matEndDate formControlName="to" placeholder="End date" />
        </mat-date-range-input>
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker"
        ></mat-datepicker-toggle>
        <mat-date-range-picker
          #picker
          panelClass="datepicker-bg"
        ></mat-date-range-picker>

        @if (filterDateRange.controls.from.hasError('matStartDateInvalid')) {
        <mat-error>Invalid start date</mat-error>
        } @if (filterDateRange.controls.to.hasError('matEndDateInvalid')) {
        <mat-error>Invalid end date</mat-error>
        }
      </mat-form-field>
    </span>
    <span class="col-span-1"></span>

    <span class="w-[14%] rounded px-4 py-2 flex flex-col justify-center">
      <h4 class="text-sm font-normal text-gray-500">Total</h4>
      <span class="text-orange-400 font-bold text-2xl">{{
        data.totalCases
      }}</span>
    </span>
    <span
      class="w-[14%] border-l-2 rounded px-4 py-2 flex flex-col justify-center"
    >
      <h4 class="text-sm font-normal text-gray-500">Ongoing</h4>
      <span class="text-orange-400 font-bold text-2xl">{{
        data.totalInProgressCases
      }}</span>
    </span>
    <span
      class="w-[14%] border-l-2 rounded px-4 py-2 flex flex-col justify-center"
    >
      <h4 class="text-sm font-normal text-gray-500">Completed</h4>
      <span class="text-orange-400 font-bold text-2xl">{{
        data.totalCompletedCases
      }}</span>
    </span>
    <span
      class="w-[14%] border-l-2 rounded px-4 py-2 flex flex-col justify-center hover:bg-gray-200 cursor-pointer"
      [ngClass]="data.totalOverdueCases > 0 ? 'overdue-panel' : ''"
      (click)="filterOverdueBusinessCases()"
    >
      <h4 class="text-sm font-normal text-gray-500">Overdue</h4>
      <span class="text-orange-400 font-bold text-2xl">{{
        data.totalOverdueCases
      }}</span>
    </span>
    <span
      class="w-[14%] border-l-2 rounded px-4 py-2 flex flex-col justify-center hover:bg-gray-200 cursor-pointer"
      [ngClass]="data.totalDelayedCases > 0 ? 'delayed-panel' : ''"
      (click)="filterDelayedBusinessCases()"
    >
      <h4 class="text-sm font-normal text-gray-500">Delayed</h4>
      <span class="text-orange-400 font-bold text-2xl">{{
        data.totalDelayedCases
      }}</span>
    </span>
  </div>
</div>
