import {
  Component,
  effect,
  EventEmitter,
  inject,
  input,
  Input,
  InputSignal, OnChanges,
  OnInit,
  Output, SimpleChanges
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { Collaborator } from '../../interfaces/update-business-case-payload.interface';
import { MatIconModule } from '@angular/material/icon';
import { IBusinessCaseAccount } from '../../interfaces/collaborator.interface';
import { Subject, takeUntil } from 'rxjs';
import { IStageCollaborationInput } from '../../interfaces/collaborator-input.interface';
import { FilterByPipe, NgArrayPipesModule } from 'ngx-pipes';

@Component({
  selector: 'bidvest-collaborators-selector',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatIconModule,
    NgArrayPipesModule
  ],
  templateUrl: './collaborators-selector.component.html',
  styleUrl: './collaborators-selector.component.scss',
  providers: [FilterByPipe]
})
export class CollaboratorsSelectorComponent implements OnInit, OnChanges {
  @Input() businessCaseAccountsPerProvince: Array<IBusinessCaseAccount> = new Array<IBusinessCaseAccount>();
  @Output()
  public collaboratorsSelection: EventEmitter<IStageCollaborationInput> = new EventEmitter<IStageCollaborationInput>();
  public collaboratorsData = input.required<Collaborator>();
  public isReadOnly: InputSignal<boolean> = input(false);
  public businessCaseAccounts: InputSignal<Array<IBusinessCaseAccount>> = input<
    Array<IBusinessCaseAccount>
  >([]);
  public collaboratorsForm!: FormGroup;
  public accounts: Array<IBusinessCaseAccount> = [];
  public availableStageOwnerIds: Array<number> = [];
  public availableReviewerIds: Array<number> = [];
  private readonly _destroy$: Subject<void> = new Subject();

  public constructor(private readonly _fb: FormBuilder) {
    effect(() => {
      this.collaboratorsForm.patchValue({
        owner: this.collaboratorsData().ownerId,
        reviewers: this.collaboratorsData().reviewerIds
      });

      this.accounts = this.businessCaseAccounts();

      // Set accounts available for reviewer selection by filtering
      // out accounts selected as stage owners
      this.availableReviewerIds = this.accounts
        .filter((account) => account.id !== this.collaboratorsData().ownerId)
        .map((account) => account.id);

      // Set accounts available for stage owner selection by filtering
      // out accounts selected as reviewers
      this.collaboratorsData().reviewerIds.forEach((value) => {
        this.availableStageOwnerIds = this.accounts
          .filter((account) => account.id !== value)
          .map((account) => account.id);
      });

      if (this.isReadOnly()) {
        this.collaboratorsForm.disable();
      }
    });
  }

  public ngOnInit(): void {
    this.collaboratorsForm = this._fb.group({
      owner: [null, Validators.required],
      reviewers: [null, Validators.required]
    });

    this.availableReviewerIds = this.accounts.map((account) => account.id);
    this.availableStageOwnerIds = this.accounts.map((account) => account.id);

    this.collaboratorsForm.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (values: { owner: number; reviewers: Array<number> }) => {
          if (values.owner !== null && values.reviewers.length > 0) {
            this.collaboratorsSelection.emit({
              id: this.collaboratorsData().id,
              ownerId: values.owner,
              reviewerIds: [...values.reviewers],
              businessCaseStageName:
                this.collaboratorsData().businessCaseStageName
            });
          }
        }
      });
  }

  public updateStageOwnerFilter(event: MatSelectChange): void {
    this.availableStageOwnerIds = this.accounts
      .filter((account) => !(event.value as Array<number>).includes(account.id))
      .map((account) => account.id);
  }

  public updateReviewerFilter(event: MatSelectChange): void {
    this.availableReviewerIds = this.accounts
      .filter((account) => account.id !== event.value)
      .map((account) => account.id);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['businessCaseAccountsPerProvince']) {
        this.setInitialStagesOwnersByProvinceAndStage();
        this.setInitialStagesReviewersByProvinceAndStage();
    }
  }

  private setInitialStagesOwnersByProvinceAndStage(): void {
    // loop through initialStageCollaborators and update the owner id per stage
    this.businessCaseAccountsPerProvince.forEach(usersPerProvince => {
      if(usersPerProvince.role === 'SITE_PROJECT_MANAGER' && this.collaboratorsData().businessCaseStageName === 'SETUP') {
        this.collaboratorsForm.get("owner")?.setValue(usersPerProvince.id);
      } else if((usersPerProvince.role === 'ENGINEERING_SERVICE_MANAGER' || usersPerProvince.role === 'SITE_PROJECT_MANAGER')
        && this.collaboratorsData().businessCaseStageName === 'COST_GATHERING') {
        this.collaboratorsForm.get("owner")?.setValue(usersPerProvince.id);
      } else if(usersPerProvince.role === 'SITE_PROJECT_MANAGER' && this.collaboratorsData().businessCaseStageName === 'COST_SOFT_SERVICES') {
        this.collaboratorsForm.get("owner")?.setValue(usersPerProvince.id);
      } else if(usersPerProvince.role === 'ENGINEERING_SERVICE_MANAGER' && this.collaboratorsData().businessCaseStageName === 'COST_ENGINEERING_SERVICES') {
        this.collaboratorsForm.get("owner")?.setValue(usersPerProvince.id);
      } else if(usersPerProvince.role === 'ENGINEERING_SERVICE_MANAGER' && this.collaboratorsData().businessCaseStageName === 'VERIFY_ASSET_LIST') {
        this.collaboratorsForm.get("owner")?.setValue(usersPerProvince.id);
      } else if(usersPerProvince.role === 'ENGINEERING_SERVICE_MANAGER' && this.collaboratorsData().businessCaseStageName === 'GATHER_ASSET_PRICE') {
        this.collaboratorsForm.get("owner")?.setValue(usersPerProvince.id);
      } else if(usersPerProvince.role === 'PROCUREMENT' && this.collaboratorsData().businessCaseStageName === 'PROCUREMENT_TO_PRICE') {
        this.collaboratorsForm.get("owner")?.setValue(usersPerProvince.id);
      } else if(usersPerProvince.role === 'ENGINEERING_SERVICE_MANAGER' && this.collaboratorsData().businessCaseStageName === 'PREPARE_ENGINEERING_QUOTE') {
        this.collaboratorsForm.get("owner")?.setValue(usersPerProvince.id);
      } else if(usersPerProvince.role === 'PRINCIPLE_ENGINEER' && this.collaboratorsData().businessCaseStageName === 'ENGINEERING_REVIEW') {
        this.collaboratorsForm.get("owner")?.setValue(usersPerProvince.id);
      } else if(usersPerProvince.role === 'GOVERNANCE' && this.collaboratorsData().businessCaseStageName === 'GOVERNANCE_REVIEW') {
        this.collaboratorsForm.get("owner")?.setValue(usersPerProvince.id);
      } else if(usersPerProvince.role === 'LEGAL' && this.collaboratorsData().businessCaseStageName === 'LEGAL_REVIEW') {
        this.collaboratorsForm.get("owner")?.setValue(usersPerProvince.id);
      } else if(usersPerProvince.role === 'BIDVEST_FINANCIAL_MANAGEMENT' && this.collaboratorsData().businessCaseStageName === 'CUSTOMER_FINANCE_REVIEW') {
        this.collaboratorsForm.get("owner")?.setValue(usersPerProvince.id);
      } else if(usersPerProvince.role === 'PRINCIPLE_ENGINEER' && this.collaboratorsData().businessCaseStageName === 'CUSTOMER_ENGINEERING_REVIEW') {
        this.collaboratorsForm.get("owner")?.setValue(usersPerProvince.id);
      } else if(usersPerProvince.role === 'PRINCIPLE_ENGINEER' && this.collaboratorsData().businessCaseStageName === 'CUSTOMER_FINAL_REVIEW') {
        this.collaboratorsForm.get("owner")?.setValue(usersPerProvince.id);
      } else if(usersPerProvince.role === 'PRINCIPLE_ENGINEER' && this.collaboratorsData().businessCaseStageName === 'GENERATE_CHANGE_NOTE') {
        this.collaboratorsForm.get("owner")?.setValue(usersPerProvince.id);
      }
    });
  }

  private setInitialStagesReviewersByProvinceAndStage(): void {
    // loop through initialStageCollaborators and update the reviewer id per stage
    this.businessCaseAccountsPerProvince.forEach(usersPerProvince => {
      if(usersPerProvince.role === 'SITE_PROJECT_MANAGER' && this.collaboratorsData().businessCaseStageName === 'SETUP') {
        this.collaboratorsForm.get("reviewers")?.setValue([usersPerProvince.id]);
      } else if(usersPerProvince.role === 'BIDVEST_FINANCIAL_MANAGEMENT' && this.collaboratorsData().businessCaseStageName === 'COST_GATHERING') {
        this.collaboratorsForm.get("reviewers")?.setValue([usersPerProvince.id]);
      } else if(usersPerProvince.role === 'BIDVEST_FINANCIAL_MANAGEMENT' && this.collaboratorsData().businessCaseStageName === 'COST_SOFT_SERVICES') {
        this.collaboratorsForm.get("reviewers")?.setValue([usersPerProvince.id]);
      } else if(usersPerProvince.role === 'PRINCIPLE_ENGINEER' && this.collaboratorsData().businessCaseStageName === 'COST_ENGINEERING_SERVICES') {
        this.collaboratorsForm.get("reviewers")?.setValue([usersPerProvince.id]);
      } else if(usersPerProvince.role === 'PRINCIPLE_ENGINEER' && this.collaboratorsData().businessCaseStageName === 'VERIFY_ASSET_LIST') {
        this.collaboratorsForm.get("reviewers")?.setValue([usersPerProvince.id]);
      } else if(usersPerProvince.role === 'PRINCIPLE_ENGINEER' && this.collaboratorsData().businessCaseStageName === 'GATHER_ASSET_PRICE') {
        this.collaboratorsForm.get("reviewers")?.setValue([usersPerProvince.id]);
      } else if(usersPerProvince.role === 'PROCUREMENT' && this.collaboratorsData().businessCaseStageName === 'PROCUREMENT_TO_PRICE') {
        this.collaboratorsForm.get("reviewers")?.setValue([usersPerProvince.id]);
      } else if(usersPerProvince.role === 'PRINCIPLE_ENGINEER' && this.collaboratorsData().businessCaseStageName === 'PREPARE_ENGINEERING_QUOTE') {
        this.collaboratorsForm.get("reviewers")?.setValue([usersPerProvince.id]);
      } else if(usersPerProvince.role === 'PRINCIPLE_ENGINEER' && this.collaboratorsData().businessCaseStageName === 'ENGINEERING_REVIEW') {
        this.collaboratorsForm.get("reviewers")?.setValue([usersPerProvince.id]);
      } else if(usersPerProvince.role === 'GOVERNANCE' && this.collaboratorsData().businessCaseStageName === 'GOVERNANCE_REVIEW') {
        this.collaboratorsForm.get("reviewers")?.setValue([usersPerProvince.id]);
      } else if(usersPerProvince.role === 'LEGAL' && this.collaboratorsData().businessCaseStageName === 'LEGAL_REVIEW') {
        this.collaboratorsForm.get("reviewers")?.setValue([usersPerProvince.id]);
      } else if(usersPerProvince.role === 'BIDVEST_FINANCIAL_MANAGEMENT' && this.collaboratorsData().businessCaseStageName === 'CUSTOMER_FINANCE_REVIEW') {
        this.collaboratorsForm.get("reviewers")?.setValue([usersPerProvince.id]);
      } else if(usersPerProvince.role === 'PRINCIPLE_ENGINEER' && this.collaboratorsData().businessCaseStageName === 'CUSTOMER_ENGINEERING_REVIEW') {
        this.collaboratorsForm.get("reviewers")?.setValue([usersPerProvince.id]);
      } else if(usersPerProvince.role === 'PRINCIPLE_ENGINEER' && this.collaboratorsData().businessCaseStageName === 'CUSTOMER_FINAL_REVIEW') {
        this.collaboratorsForm.get("reviewers")?.setValue([usersPerProvince.id]);
      } else if(usersPerProvince.role === 'PRINCIPLE_ENGINEER' && this.collaboratorsData().businessCaseStageName === 'GENERATE_CHANGE_NOTE') {
        this.collaboratorsForm.get("reviewers")?.setValue([usersPerProvince.id]);
      }
    });
  }
}
