<div class="w-full overflow-x-auto">
  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="fullName">
      <th mat-header-cell *matHeaderCellDef>
        <span class="font-semibold">Full name</span>
      </th>
      <td mat-cell *matCellDef="let user">
        {{ user.fullName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef>
        <span class="font-semibold">Role</span>
      </th>
      <td mat-cell *matCellDef="let user">{{ transform(user.role) }}</td>
    </ng-container>

    <ng-container matColumnDef="province">
      <th mat-header-cell *matHeaderCellDef>
        <span class="font-semibold">Province</span>
      </th>
      <td mat-cell *matCellDef="let user">{{ user.province }}</td>
    </ng-container>
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>
        <span class="font-semibold">Email</span>
      </th>
      <td mat-cell *matCellDef="let user">{{ user.emailAddress }}</td>
    </ng-container>

    <ng-container matColumnDef="activated">
      <th mat-header-cell *matHeaderCellDef>
        <span class="font-semibold">Status</span>
      </th>
      <td mat-cell *matCellDef="let user">
        <span class="bg-green-500 px-4 py-2 text-white font-semibold text-xs rounded-full" *ngIf="user.activated">Activated</span>
        <span class="bg-red-500 px-4 py-2 text-white font-semibold text-xs rounded-full" *ngIf="!user.activated">Not activated</span>
        <span class="bg-green-500 px-4 py-2 text-white font-semibold text-xs rounded-full" *ngIf="user.verified">Email verified</span>
        <span class="bg-red-500 px-4 py-2 text-white font-semibold text-xs rounded-full" *ngIf="!user.verified">Email not verified</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let user">
        <div class="flex flex-row justify-end items-center w-full">
          <button
            mat-icon-button
            class="mr-2"
            matTooltip="Edit"
            (click)="editStaff(user)"
          >
            <mat-icon>edit</mat-icon>
          </button>

          <button
            mat-icon-button
            class="mr-2"
            matTooltip="Reassign"
            (click)="reassignStaff(user)"
          >
            <mat-icon>assignment</mat-icon>
          </button>

          <button
            mat-icon-button
            matTooltip="Deactivate user"
            *ngIf="user.activated"
            (click)="deactivateUser(user)"
          >
            <mat-icon class="text-green-500">toggle_on</mat-icon>
          </button>

          <button
            mat-icon-button
            matTooltip="Activate user"
            *ngIf="!user.activated"
            (click)="activateUser(user)"
          >
            <mat-icon class="text-red-500">toggle_off</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

    <tr class="w-full" *matNoDataRow>
      <td [colSpan]="displayedColumns.length">
        <div class="h-96 flex flex-row justify-center items-center font-bold">
          No Data
        </div>
      </td>
    </tr>
  </table>
</div>

<mat-paginator
  [pageSizeOptions]="[5, 10, 20]"
  showFirstLastButtons
  aria-label="Select page of users"
>
</mat-paginator>
