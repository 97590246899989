import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, Observable, Subject } from 'rxjs';
import { environment } from '@env/environment';
import { IViewSoftServices } from '../models/view-soft-services';
import { IPaginationData } from '../models/pagination-data';
import { IAddSoftServicesPayload } from '../models/add-soft-services-payload.interface';
import { AddSoftServicesUpdate } from '../models/add-soft-services-update.interface';

@Injectable({
  providedIn: 'root'
})
export class SoftServicesService {
  private baseUrl: string = environment.apiUrl;
  private _http: HttpClient = inject(HttpClient);
  private refreshSoftServiceTable = new Subject<void>;
    public refreshTable$ = this.refreshSoftServiceTable.asObservable();
  
    public triggerSoftServiceTableRefresh() {
      this.refreshSoftServiceTable.next();
    }

  public fetchBusinessCaseSoftServices(
    businessCaseId: number
  ): Promise<Array<AddSoftServicesUpdate>> {
    return firstValueFrom(
      this._http.get<Array<AddSoftServicesUpdate>>(
        `${this.baseUrl}/business-cases/${businessCaseId}/soft-services`
      )
    );
  }

  public fetchAvailableRateCardServices(
    payload: IPaginationData
  ): Promise<IViewSoftServices> {
    return firstValueFrom(
      this._http.put<IViewSoftServices>(
        `${this.baseUrl}/business-cases/rate-cards`,
        payload
      )
    );
  }

  public addSoftService(
    payload: Array<IAddSoftServicesPayload>,
    businessCaseId: number
  ): Promise<void> {
    return firstValueFrom(
      this._http.post<void>(
        `${this.baseUrl}/business-cases/${businessCaseId}/soft-services`,
        payload
      )
    );
  }
}
