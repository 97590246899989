import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  inject,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { RateCardsService } from '../../services/rate-cards.service';
import { RateCard } from '../../models/rate-card.interface';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDialog } from '@angular/material/dialog';
import { CreateRateCardOverlayComponent } from '../overlays/create-rate-card-overlay/create-rate-card-overlay.component';
import { IRateCardDataType } from '../../models/rate-card-data';
import { Category } from '../../enums/category.enum';
import { CustomToastrService } from '../../services/custom-toastr.service';
import { IToastConfig } from '../../models/toast-config';
import { SelectionModel } from '@angular/cdk/collections';
import { IRateCardFilter } from '../../models/rate-card-filter';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { User } from '../../interfaces/user.interface';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'bidvest-rate-card-table',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatMenuModule,
    MatCheckboxModule,
    FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatPaginatorModule
  ],
  templateUrl: './rate-card-table.component.html',
  styleUrl: './rate-card-table.component.scss'
})
export class RateCardTableComponent implements AfterViewInit, OnInit, OnDestroy {
  public rateCards$!: Observable<Array<IRateCardDataType>>;
  @Output()
  public rateCardId: EventEmitter<number> = new EventEmitter();
  @ViewChild(MatPaginator) public paginator!: MatPaginator;
  public data!: IRateCardFilter;
  public filterCategoriesKeys = Object.keys(Category);
  public size: number = 100;
  public currentPage: number = 1;
  public searchValue: string = '';
  public categoryValue: string = '';
  public role: string = '';
  public searchTerm: string = '';
  public dataSource = new MatTableDataSource<IRateCardDataType>([]);
  public readonly selection = new SelectionModel<IRateCardDataType>(true, []);
  public displayedColumns: string[] =
    ['serviceName', 'category', 'subCategory', 'unitCost', 'uom', 'validThrough', 'status', 'edit'];
  private _dialog: MatDialog = inject(MatDialog);
  private _rateCards: Array<IRateCardDataType> = [];
  private _rateCards$: BehaviorSubject<Array<IRateCardDataType>> = new BehaviorSubject(
    this._rateCards
  );
  private _subscriptions: Array<Subscription> = [];

  constructor(
    private readonly _toastr: ToastrService,
    private readonly _rateCardService: RateCardsService) {}

  public ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  public async selectedRateCardId(id: number) {
    this.rateCardId.emit(id);
    await this.fetchAllRateCards();
  }

  public async deleteRateCard(id: number) {
    this._rateCardService.deleteRateCard(id);
    this._toastr.success('Rate card has been successfully deleted!');
    await this.fetchAllRateCards();
  }

  public async ngOnInit() {
    this.rateCards$ = this._rateCards$.asObservable();
    await this.fetchAllRateCards();
  }

  public ngOnDestroy(): void {
    this._subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  public showNewRateCardDialog(): void {
    const dialogRef = this._dialog.open(CreateRateCardOverlayComponent, {
      width: '600px',
      height: '710px'
    });

    const subscription = dialogRef.afterClosed().subscribe(() => {
      this.fetchAllRateCards();
    });

    this._subscriptions.push(subscription);
  }

  public getStatus(date: string) {
    const currentDate = new Date();
    const validUntil = new Date(date);
    if (validUntil >= currentDate) {
      return 'Valid'
    } else return 'Invalid'
  }

  public statusChipChange(valid: string) {
    return valid === 'Valid' ?
      'status-chip-valid' :
      'status-chip-invalid'
  }

  public async fetchAllRateCards() {
    const subscription = this._rateCardService
      .fetchRateCards({
        currentPage: this.currentPage,
        category: this.categoryValue,
        size: this.size,
        searchTerm: this.searchValue
      })
      .subscribe((res) => {
        this.data = res;
        this.dataSource = new MatTableDataSource(this.data.rateCards);
        this.dataSource.paginator = this.paginator
        this._rateCards = [...res.rateCards];
        this._rateCards$.next(this._rateCards);
        return res;
      });

    this._subscriptions.push(subscription);
  }

  public async triggerChange() {
   await this.fetchAllRateCards();
  }

  public async applyFilter(event: string) {
    this.categoryValue = event;
    await this.fetchAllRateCards();
  }

  public transform(value: string): string {
    if (!value) return '';
    const formatted = value.replace(/_/g, ' ');
    return formatted
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase());
  }
}
