import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import { StaffTableService } from '../../../services/staff-table-service';
import { ToastrService } from 'ngx-toastr';
import { IUpdateStaffData } from '../../../models/update-staff-data';
import { PermissionsSelectorComponent } from '../../permissions-selector/permissions-selector.component';
import { IUserRoles } from '../../../models/user-roles';
import { UserService } from '../../../services/user.service';
import { UserPermission } from '../../../interfaces/user-permission.interface';
import { User } from '../../../interfaces/user.interface';
import { error } from 'console';

interface Permission {
  read: boolean;
  write: boolean;
  update: boolean;
  delete: boolean;
  permissionFeature: string;
  preApprove: boolean;
}

@Component({
  selector: 'bidvest-manage-staff-overlay',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    FormsModule,
    MatDatepickerModule,
    MatDividerModule,
    MatCheckboxModule,
    PermissionsSelectorComponent,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose
  ],
  templateUrl: './manage-staff-details-overlay.component.html',
  providers: [provideNativeDateAdapter()],
  styleUrl: './manage-staff-details-overlay.component.scss'
})
export class ManageStaffOverlayComponent implements OnInit {
  public form!: FormGroup;
  public roles: IUserRoles[] = [];
  public permissionFeatures: Array<string> = [
    'DASHBOARD',
    'USERS',
    'PROFILE',
    'RATE_CARD',
    'BUSINESS_CASE'
  ];
  public userPermissions: Array<UserPermission> | undefined;
  public constructor(
    private readonly _fb: FormBuilder,
    private readonly _staffService: StaffTableService,
    private readonly _toast: ToastrService,
    private readonly _userService: UserService,
    private readonly _dialogRef: MatDialogRef<ManageStaffOverlayComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { user: User }
  ) {
    this.userPermissions = this.data.user.userPermissions;

    this.form = this._fb.group({
      id: [this.data.user.id, [Validators.required]],
      fullName: [this.data.user.fullName, [Validators.required]],
      emailAddress: [this.data.user.emailAddress, [Validators.required]],
      role: [this.data.user.role, [Validators.required]],
      province: [this.data.user.province, [Validators.required]],
      permissions: this.buildForm()
    });
  }

  public async ngOnInit(): Promise<void> {
    this.roles = [...this._userService.userRoles];
  }

  public async updateStaff() {
    if (this.form.valid) {
      const staffUpdatedDetails: IUpdateStaffData = {
        id: this.form.value.id,
        fullName: this.form.value.fullName,
        emailAddress: this.form.value.emailAddress,
        role: this.form.value.role,
        province: this.form.value.province,
        // @ts-ignore
        userPermissions: this.mapPermissionsToArray(this.form.value.permissions, this.userPermissions)
      };
      try {
        await this._staffService
        .updateSelectedStaff(staffUpdatedDetails);
        this._toast.success('Staff details has been updated successfully');
        this._dialogRef.close();
      }
      catch {
        () => {
          this._toast.error('Please ensure you complete all the required fields.');
        }
      }
    }
  }

  public normalizeControlName(name: string): string {
    if (name.split('_').length > 1) {
      const splitStrings: Array<string> = name.split('_');
      return splitStrings[0]
        .trim()
        .toLowerCase()
        .concat(
          splitStrings[1]
            .charAt(0)
            .toUpperCase()
            .trim()
            .concat(
              splitStrings[1]
                .substring(1, splitStrings[1].length)
                .toLowerCase()
                .trim()
            )
        );
    } else {
      return name.toLowerCase().trim();
    }
  }

  public findPermissionByFeature(featureName: string): UserPermission | undefined {
    return (
      this.userPermissions?.find((permission) => {
        return permission.permissionFeature === featureName;
      }) || undefined
    );
  }

  private buildForm(): FormGroup {
    const fb = new FormBuilder();
    const formControls = this.permissionFeatures?.reduce((acc, feature) => {
      // Find the permission object for the current feature
      const permission = this.userPermissions?.find(
        (p) => p.permissionFeature === feature
      );

      acc[this.normalizeControlName(feature)] = [
        {
          read: permission?.read ?? false,
          write: permission?.write ?? false,
          update: permission?.update ?? false,
          delete: permission?.delete ?? false,
          permissionFeature: feature,
          preApprove: false
        },
        Validators.required
      ];

      return acc;
    }, {} as { [key: string]: any });

    return fb.group(formControls);
  }

  private mapPermissionsToArray(featurePermissions: Record<string, Permission>, userPermissions: UserPermission[]): UserPermission[] {
    const result: UserPermission[] = [];

    for (const featureKey in featurePermissions) {
      if (featurePermissions.hasOwnProperty(featureKey)) {
        const feature = featurePermissions[featureKey];

        // Find matching permissionFeature in userPermissions
        const userPermission = userPermissions.find(
          (p) => p.permissionFeature === feature.permissionFeature
        );

        result.push({
          // @ts-ignore
          id: userPermission?.id ?? null, // Use id from userPermissions or null if not found
          ...feature, // Spread the feature details
        });
      }
    }

    return result;
  }

  public closeStaffDialog() {
    this._dialogRef.close();
  }

  public transform(value: string): string {
    if (!value) return '';
    const formatted = value.replace(/_/g, ' ');
    return formatted
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase());
  }

}
