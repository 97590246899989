import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BusinessCasesMetricsPanelComponent } from '../../components/business-cases-metrics-panel/business-cases-metrics-panel.component';
import { BusinessCasesTableComponent } from '../../components/tables/business-cases-table/business-cases-table.component';
import { IBusinessCaseTableItem } from '../../models/business-case-table-item';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CreateBusinessCaseDialogComponent } from '../../components/create-business-case-dialog/create-business-case-dialog.component';
import { BusinessCaseTableAction } from '../../models/business-case-table-action';
import { BusinessCaseAction } from '../../enums/business-case-actions.enum';
import { BusinessCaseService } from '../../services/business-case.service';
import { firstValueFrom, Subject, takeUntil } from 'rxjs';
import { OverviewTotals } from '../../interfaces/overview-totals.interface';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { IBusinessCaseTableFilter } from '../../interfaces/business-case-table-filter.interface';
import { MatChipsModule } from '@angular/material/chips';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmBusinessCaseDeletionDialogComponent } from '../../components/dialogs/confirm-business-case deletion-dialog/confirm-business-case deletion-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { format, startOfMonth } from 'date-fns';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormField,
  MatFormFieldModule,
  MatLabel,
  MatSuffix
} from '@angular/material/form-field';
import { MatInput, MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatOption } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';
import { BusinessCaseStagesEnum } from '../../enums/business-case-stages.enum';
import { LocalStorageService } from '@bidvest/shared';

@Component({
  selector: 'bidvest-business-cases',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    BusinessCasesMetricsPanelComponent,
    BusinessCasesTableComponent,
    MatTooltipModule,
    MatChipsModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    MatFormFieldModule,
    MatFormField,
    MatInput,
    MatLabel,
    MatSuffix,
    ReactiveFormsModule,
    MatOption,
    MatSelect
  ],
  templateUrl: './business-cases.component.html',
  styleUrl: './business-cases.component.scss',
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { subscriptSizing: 'dynamic' }
    }
  ]
})
export class BusinessCasesComponent implements OnInit, OnDestroy {
  @ViewChild('metricsPanel')
  public metricsPanel!: BusinessCasesMetricsPanelComponent;

  public data!: Array<IBusinessCaseTableItem>;
  public overviewTotals!: OverviewTotals;
  public activeStatusFilter!: string;
  public searchTerm: string = '';
  public filterCategoriesKeys = Object.keys(BusinessCaseStagesEnum);
  public businessCaseStage: string = '';

  private readonly _destroy$: Subject<void> = new Subject();
  public businessCaseStatus: string = '';
  public lastUpdated: Date = new Date();

  constructor(
    private readonly _dialog: MatDialog,
    private readonly _businessCaseService: BusinessCaseService,
    private readonly _router: Router,
    private readonly _toastr: ToastrService,
    private readonly _loader: NgxUiLoaderService,
    private readonly _localStorageService: LocalStorageService
  ) {}

  public ngOnInit(): void {
    this.trackActiveFilter();
    this.fetchOverviewTotal();
    this.generateBusinessCaseTableData();
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public generateBusinessCaseTableData(): void {
    this._businessCaseService
      .generateBusinessCaseTable()
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (res) => {
          this.data = res.businessCases;
          this.setLastUpdated();
        }
      });
  }

  public async refreshBusinessCaseTableData(): Promise<void> {
    this._loader.start();
    this.data = (
      await firstValueFrom(
        this._businessCaseService.generateBusinessCaseTable()
      )
    ).businessCases;

    this.setLastUpdated();
    this._loader.stop();
  }

  public fetchOverviewTotal(): void {
    this._businessCaseService
      .fetchOverviewTotals()
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (data) => {
          this.overviewTotals = data;
        }
      });
  }

  public updateTableDateRange(range: {
    from: string;
    to: string | null;
  }): void {
    if (range) {
      const filter: IBusinessCaseTableFilter = {
        currentPage: 1,
        size: 200,
        sortBy: 'initiationDate',
        sortDirection: 'Desc',
        startDate: range.from,
        endDate: range.to ? range.to : null,
        filters: {
          overdue: false,
          delayed: false,
          businessCaseStatus: null,
          stageName: ''
        },
        searchTerm: ''
      };

      this._businessCaseService.businessCaseTableFilter.next(filter);
    }
  }

  public showNewBusinessCaseDialog(): void {
    const dialogRef: MatDialogRef<CreateBusinessCaseDialogComponent> =
      this._dialog.open(CreateBusinessCaseDialogComponent, {
        width: '100vw',
        height: '100vh',
        maxWidth: '100%',
        maxHeight: '100%'
      });

    dialogRef.afterClosed().subscribe(() => {
      this.fetchOverviewTotal();
      this.generateBusinessCaseTableData();
    });
  }

  public handleBusinessCaseAction(event: BusinessCaseTableAction): void {
    switch (event.actionType) {
      case BusinessCaseAction.VIEW:
        this._router.navigateByUrl(`/business-cases/${event.id}`);

        break;

      case BusinessCaseAction.DELETE:
        const dialogRef: MatDialogRef<ConfirmBusinessCaseDeletionDialogComponent> =
          this._dialog.open(ConfirmBusinessCaseDeletionDialogComponent, {
            width: '600px',
            height: '350px',
            data: event.data,
            panelClass: ['rounded-lg', 'p-4']
          });

        dialogRef.afterClosed().subscribe({
          next: async (shouldDelete: boolean) => {
            if (shouldDelete) {
              await firstValueFrom(
                this._businessCaseService.deleteBusinessCase(event.id)
              );

              this.fetchOverviewTotal();
              this.generateBusinessCaseTableData();
              this._toastr.success('Business case deleted successfully');
            }
          }
        });

        break;

      default:
        break;
    }
  }

  public resetTableFilters(): void {
    const defaultFilter: IBusinessCaseTableFilter = {
      currentPage: 1,
      size: 200,
      sortBy: 'initiationDate',
      sortDirection: 'Desc',
      startDate: format(startOfMonth(new Date()) as Date, 'yyyy-MM-dd'),
      endDate: null,
      filters: {
        overdue: false,
        delayed: false,
        businessCaseStatus: null,
        stageName: ''
      },
      searchTerm: ''
    };
    this.searchTerm = '';
    this.businessCaseStage = '';
    this.businessCaseStatus = '';

    this.metricsPanel.resetDateRange();
    this._businessCaseService.businessCaseTableFilter.next(defaultFilter);
  }

  public trackActiveFilter(): void {
    this._businessCaseService.businessCaseTableFilter$
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (data: IBusinessCaseTableFilter) => {
          if (data.filters.delayed === true) {
            this.activeStatusFilter = 'delayed';
            this.businessCaseStatus = 'delayed';
          } else if (data.filters.overdue === true) {
            this.activeStatusFilter = 'overdue';
            this.businessCaseStatus = 'overdue';
          } else {
            this.activeStatusFilter = '';
          }
        }
      });
  }

  public search() {
    const filter: IBusinessCaseTableFilter = {
      ...this._businessCaseService.defaultFilter
    };

    filter.filters.delayed = false;
    filter.filters.overdue = false;
    filter.filters.stageName = '';
    filter.filters.businessCaseStatus = null;

    filter.searchTerm = this.searchTerm;
    this._businessCaseService.businessCaseTableFilter.next(filter);
  }

  public filterByStage(event: string) {
    this.businessCaseStage = event;
    const filter: IBusinessCaseTableFilter = {
      ...this._businessCaseService.defaultFilter
    };

    filter.filters.delayed = false;
    filter.filters.overdue = false;
    filter.filters.businessCaseStatus = null;

    filter.filters.stageName = this.businessCaseStage;
    this._businessCaseService.businessCaseTableFilter.next(filter);
  }

  public filterByStatus() {
    const filter: IBusinessCaseTableFilter = {
      ...this._businessCaseService.defaultFilter
    };

    filter.filters.stageName = '';
    filter.filters.delayed = false;
    filter.filters.overdue = false;
    filter.filters.businessCaseStatus = null;

    switch (this.businessCaseStatus) {
      case 'delayed':
        filter.filters.delayed = true;
        break;

      case 'overdue':
        filter.filters.overdue = true;
        break;

      default:
        filter.filters.delayed = false;
        filter.filters.overdue = false;
        break;
    }

    this._businessCaseService.businessCaseTableFilter.next(filter);
  }

  public setLastUpdated(): void {
    this._localStorageService.setItem('lastUpdated', new Date().toISOString());
  }

  public getLastUpdated(): Date {
    if (this._localStorageService.getItem('lastUpdated')) {
      return new Date(
        this._localStorageService.getItem('lastUpdated') as string
      );
    }

    return new Date();
  }
}
