import { Component, inject, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BusinessCaseStage } from '../../interfaces/business-case-setup.interface';
import { Router } from '@angular/router';
import { backOfficeRouteKeys } from '@bidvest/shared';
import { IBusinessCaseStageConfig } from '../../interfaces/business-case-stage-config.interface';
import { IBusinessCaseOverview } from '../../interfaces/business-case-overview.interface';

@Component({
  selector: 'bidvest-business-case-stage-indicator',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './business-case-stage-indicator.component.html',
  styleUrl: './business-case-stage-indicator.component.scss'
})
export class BusinessCaseStageIndicatorComponent {
  @Input()
  public get stages(): Array<IBusinessCaseStageConfig> {
    return this._stages;
  }

  public set stages(
    value: IBusinessCaseStageConfig | Array<IBusinessCaseStageConfig>
  ) {
    if (Array.isArray(value)) {
      this._stages = [...value];
    } else {
      this._stages = [value];
    }
  }

  @Input()
  public overview: IBusinessCaseOverview | null = null;

  private _stages: Array<IBusinessCaseStageConfig> = [];
  private _router: Router = inject(Router);

  public goToStageView(stage: IBusinessCaseStageConfig): void {
    switch (stage.stageName) {
      case 'SETUP':
        if (stage.stageActive || stage.stageCompleted) {
          if (stage.canEdit) {
            this._router.navigateByUrl(
              `${backOfficeRouteKeys['BUSINESS_CASES']}/${this.overview?.id}`
            );
          } else if (stage.canRead) {
            this._router.navigateByUrl(
              `${backOfficeRouteKeys['BUSINESS_CASES']}/${this.overview?.id}?read-only=true`
            );
          }
        }

        break;
      case 'COST_SOFT_SERVICES':
        if (stage.stageActive || stage.stageCompleted) {
          if (stage.canEdit) {
            this._router.navigateByUrl(
              `business-cases/${this.overview?.id}/soft-services`
            );
          } else if (stage.canRead) {
            this._router.navigateByUrl(
              `business-cases/${this.overview?.id}/soft-services?read-only=true`
            );
          }
        }
        break;
      case 'COST_ENGINEERING_SERVICES':
        if (stage.stageActive || stage.stageCompleted) {
          if (stage.canEdit) {
            this._router.navigateByUrl(
              `business-cases/${this.overview?.id}/verify-asset-list`
            );
          } else if (stage.canRead) {
            this._router.navigateByUrl(
              `business-cases/${this.overview?.id}/verify-asset-list?read-only=true`
            );
          }
        }
        break;
      case 'VERIFY_ASSET_LIST':
        if (stage.stageActive || stage.stageCompleted) {
          if (stage.canEdit) {
            this._router.navigateByUrl(
              `business-cases/${this.overview?.id}/verify-asset-list`
            );
          } else if (stage.canRead) {
            this._router.navigateByUrl(
              `business-cases/${this.overview?.id}/verify-asset-list?read-only=true`
            );
          }
        }
        break;
      case 'GATHER_ASSET_PRICE':
        if (stage.stageActive || stage.stageCompleted) {
          if (stage.canEdit) {
            this._router.navigateByUrl(
              `business-cases/${this.overview?.id}/gather-asset-price`
            );
          } else if (stage.canRead) {
            this._router.navigateByUrl(
              `business-cases/${this.overview?.id}/gather-asset-price`
            );
          }
        }
        break;
      case 'PROCUREMENT_TO_PRICE':
        if (stage.stageActive || stage.stageCompleted) {
          if (stage.canEdit) {
            this._router.navigateByUrl(
              `business-cases/${this.overview?.id}/procurement-to-price`
            );
          } else if (stage.canRead) {
            this._router.navigateByUrl(
              `business-cases/${this.overview?.id}/procurement-to-price`
            );
          }
        }
        break;
      case 'PREPARE_ENGINEERING_QUOTE':
        if (stage.canEdit) {
          this._router.navigateByUrl(
            `business-cases/${this.overview?.id}/engineering-services-quote`
          );
        } else if (stage.canRead) {
          this._router.navigateByUrl(
            `business-cases/${this.overview?.id}/engineering-services-quote`
          );
        }
        break;
      case 'ENGINEERING_REVIEW':
        if (stage.stageActive || stage.stageCompleted) {
          this._router.navigateByUrl(
            `${backOfficeRouteKeys['ENGINEERING_REVIEW']}`
          );
        }
        break;
      case 'GOVERNANCE_REVIEW':
        if (stage.stageActive || stage.stageCompleted) {
          this._router.navigateByUrl(
            `${backOfficeRouteKeys['PREPARE_ENGINEERING_QUOTE']}`
          );
        }
        break;
      case 'LEGAL_REVIEW':
        if (stage.stageActive || stage.stageCompleted) {
          this._router.navigateByUrl(
            `business-cases/${this.overview?.id}/asset-list-read-only`
          );
        }
        break;
      case 'CUSTOMER_FINANCE_REVIEW':
        if (stage.stageActive || stage.stageCompleted) {
          this._router.navigateByUrl(
            `business-cases/${this.overview?.id}/soft-services-quote`
          );
        }
        break;
      case 'CUSTOMER_ENGINEERING_REVIEW':
        if (stage.stageActive || stage.stageCompleted) {
          this._router.navigateByUrl(
            `business-cases/${this.overview?.id}/engineering-services-quote`
          );
        }
        break;
      case 'CUSTOMER_FINAL_REVIEW':
        if (stage.stageActive || stage.stageCompleted) {
          this._router.navigateByUrl(
            `business-cases/${this.overview?.id}/soft-services-quote`
          );
        }
        break;
      case 'GENERATE_CHANGE_NOTE':
        console.log('Still need to show what this is');
        break;
      default:
        break;
    }
  }
}
