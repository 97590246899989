import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roundOffByTwo',
  standalone: true
})
export class RoundOffByTwoPipe implements PipeTransform {
  transform(amount: number) {
    if (amount === undefined || amount === null || isNaN(amount)) {
      return 0.00;
    } else {
      return parseFloat(parseFloat(String(amount)).toFixed(2));
    }
  }
}
