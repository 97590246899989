<div class="flex flex-row justify-between p-4">
  <p class="font-bold text-primary-blue text-xl">Asset Seen</p>
  <mat-icon class="text-gray-400 cursor-pointer" (click)="closeAssetList()"
    >close</mat-icon
  >
</div>
<div matDialogContent class="p-4 flex flex-col gap-4">
  <form [formGroup]="form" class="flex flex-col gap-4">
    <mat-form-field class="w-full" appearance="outline">
      <mat-label>Equipment ID</mat-label>
      <input type="text" matInput formControlName="equipmentId" />
    </mat-form-field>

    <mat-form-field class="w-full" appearance="outline">
      <mat-label>Discipline</mat-label>
      <input type="text" matInput formControlName="discipline" />
    </mat-form-field>

    <mat-form-field class="w-full" appearance="outline">
      <mat-label>Description</mat-label>
      <input type="text" matInput formControlName="description" />
    </mat-form-field>

    <div class="py-4">
      <mat-divider></mat-divider>
    </div>

    <mat-form-field class="w-full" appearance="outline">
      <mat-label>Action status</mat-label>
      <mat-select formControlName="actionStatus">
        @for (actions of actionStatusOptions; track actions) {
        <mat-option [value]="actions">{{ actions }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  </form>
</div>
<div matDialogActions>
  <button
    mat-flat-button
    color="accent"
    [disabled]="!form.valid"
    (click)="verifyAsset()"
    class="w-full m-4"
  >
    Submit
  </button>
</div>
