import { Component, input, InputSignal, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  switchMap
} from 'rxjs/operators';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldModule
} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AssetListService } from 'libs/bidvest-ui/features/src/lib/features/services/asset-list.service';
import { IEquipmentRetrievalModel } from 'libs/bidvest-ui/features/src/lib/features/models/asset-list-equipment';

@Component({
  selector: 'bidvest-autocomplete-input',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule
  ],
  templateUrl: './autocomplete-input.component.html',
  styleUrl: './autocomplete-input.component.scss',
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        subscriptSizing: 'dynamic',
        appearance: 'outline'
      }
    }
  ]
})
export class AutocompleteInputComponent implements OnInit {
  public businessCaseId: InputSignal<number | undefined> = input();
  public strategy: InputSignal<string | undefined> = input();
  public searcInputControl: FormControl = new FormControl();
  public options: string[] = ['One', 'Two', 'Three'];
  public filteredOptions!: Observable<IEquipmentRetrievalModel>;

  constructor(private readonly _assetListService: AssetListService) {}

  public ngOnInit() {
    // TODO fix this
    console.log('here');
    // this.filteredOptions = this.searcInputControl.valueChanges.pipe(
    //   debounceTime(300),
    //   distinctUntilChanged(),
    //   switchMap((value) =>
    //     this._assetListService.fetchEquipmentById(
    //       this.businessCaseId() as number,
    //       value
    //     )
    //   )
    // );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }
}
