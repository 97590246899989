export const backOfficeRouteKeys: { [key: string]: string } = Object.freeze({
  LOGIN: '/auth/login',
  FORGOT_PASSWORD: '/auth/forgot-password',
  RESET_PASSWORD: '/auth/reset-password',
  SET_PASSWORD: '/auth/set-password',
  STAFFS: '/staffs',
  BUSINESS_CASE: '/business-case',
  BUSINESS_CASES: '/business-cases',
  READ_SOFT_SERVICES: '/bidvest-soft-service-read-only',
  ADD_SOFT_SERVICES: '/add-soft-services',
  CUSTOMER_FINANCE_REVIEW: '/soft-services-quote',
  ENGINEERING_SERVICES: '/features/engineering-services',
  ADD_ASSET: '/features/add-to-asset-list',
  VERIFY_ASSET_LIST_VERIFICATION: '/verify-asset-list',
  GATHER_ASSET_PRICE: '/add-to-asset-list',
  PROCUREMENT_TO_PRICE: '',
  PREPARE_ENGINEERING_QUOTE: '/engineering-services-quote',
  ENGINEERING_REVIEW: '/bidvest-asset-list-read-only',
  GOVERNANCE_REVIEW: '/bidvest-asset-list-read-only',
  LEGAL_REVIEW: '/bidvest-asset-list-read-only',
  CUSTOMER_ENGINEERING_REVIEW: '/engineering-services-quote',
  CUSTOMER_FINAL_REVIEW: '',
  GENERATE_CHANGE_NOTE: '',
  RATE_CARDS: '/rate-cards',
  UPDATE_RATE_CARD: '/features/update-rate-card',
  CREATE_RATE_CARD: '/features/create-rate-card'
});
