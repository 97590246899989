import { Route } from '@angular/router';
import { BusinessCasesComponent } from './features/views/business-cases/business-cases.component';
import { StaffComponent } from './features/views/staff/staff.component';
import { AddingEngineeringQuotesTableComponent } from './features/components/tables/add-engineering-quotes-table/add-engineering-quotes-table.component';
import { RateCardLayoutComponent } from './features/views/rate-card-layout/rate-card-layout.component';

export const featuresRoutes: Route[] = [
  {
    path: '',
    redirectTo: 'business-cases',
    pathMatch: 'full'
  },
  { path: 'business-cases', component: BusinessCasesComponent },
  {
    path: 'adding-engineering-services',
    component: AddingEngineeringQuotesTableComponent
  },
  {
    path: 'staffs',
    component: StaffComponent
  },
  {
    path: 'rate-cards',
    component: RateCardLayoutComponent
  }
];
