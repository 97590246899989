import { Component, inject, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FileUploadComponent, Provinces } from '@bidvest/shared';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { IBusinessCaseAccount } from '../../interfaces/collaborator.interface';
import { BusinessCaseStage } from '../../interfaces/business-case-setup.interface';
import { CollaboratorsSelectorComponent } from '../collaborators-selector/collaborators-selector.component';
import { Collaborator } from '../../interfaces/business-case-details.interface';
import { IStageCollaborationInput } from '../../interfaces/collaborator-input.interface';
import { BuildingData } from '../../interfaces/building-data.interface';
import { MatDialog } from '@angular/material/dialog';
import { BuildingNumberInputDialogComponent } from '../dialogs/building-number-input-dialog/building-number-input-dialog.component';
import { BusinessCaseService } from '../../services/business-case.service';

@Component({
  selector: 'bidvest-new-business-case-form',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatRadioModule,
    MatDividerModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    MatDatepickerModule,
    FileUploadComponent,
    MatOptionModule,
    MatSelectModule,
    CollaboratorsSelectorComponent
  ],
  templateUrl: './new-business-case-form.component.html',
  styleUrl: './new-business-case-form.component.scss',
  providers: [provideMomentDateAdapter()],
  exportAs: 'newBusinessCase'
})
export class NewBusinessCaseFormComponent implements OnInit {
  @Input()
  public collaborators!: Array<IBusinessCaseAccount>;
  @Input()
  public set stages(val: Array<BusinessCaseStage>) {
    this._stages = [...val];
    this.setInitialStagesCollaboratorsData();
  }
  public get stages(): Array<BusinessCaseStage> {
    return this._stages;
  }
  public usersPerProvince: IBusinessCaseAccount[] = new Array<IBusinessCaseAccount>;
  @Input()
  public businessCaseId!: number;
  public businessCaseForm!: FormGroup;
  public initialStagesCollaboratorsData: Array<Collaborator> = [];
  public selectedCollaborators: Array<Collaborator> = [];
  public requiredDocs: {
    cdaLayout: boolean;
    iopSchedule: boolean;
    rr03PropertyList: boolean;
  } = {
    cdaLayout: false,
    iopSchedule: false,
    rr03PropertyList: false
  };
  public businessCaseActionTypes: Array<string> = [
    'TERMINATION',
    'DOWN_SELL',
    'UPSELL',
    'NEW_BUILDING'
  ];
  public outletTypes: Array<string> = ['Other', 'Retail', 'Corporate', 'ATM'];
  public provinces: Array<string> = Provinces;
  private _stages: Array<BusinessCaseStage> = [];

  public constructor(
    private readonly _businessCaseService: BusinessCaseService,
    private readonly _fb: FormBuilder,
    private readonly _dialog: MatDialog) {
  }
  public ngOnInit(): void {
    this.initializeForm();
  }

  public initializeForm(): void {
    this.businessCaseForm = this._fb.group({
      businessCaseActionType: ['', Validators.required],
      name: ['', Validators.required],
      implementationDate: [new Date(), Validators.required],
      description: ['', Validators.required],
      town: ['', Validators.required],
      province: ['', Validators.required],
      buildingNumber: ['', Validators.required],
      outletType: ['', Validators.required],
      outlet: ['', Validators.required],
      collaborators: this._fb.group({})
    });

    const collaborators = this.businessCaseForm.controls[
      'collaborators'
    ] as FormGroup;

    for (let stage of this.stages) {
      collaborators.addControl(
        stage.stageName,
        new FormControl({ owner: 0, reviewers: [] }, Validators.required)
      );
    }

    // Disable only building number input
    this.businessCaseForm.get('buildingNumber')?.disable({ onlySelf: true });
  }

  public setInitialStagesCollaboratorsData(): void {
    for (let stage of this.stages) {
      this.initialStagesCollaboratorsData.push({
        id: stage.id,
        businessCaseStageName: stage.stageName,
        ownerId: 0,
        reviewerIds: []
      });
    }
  }

  public async provinceChanged(province: string): Promise<void> {
    this.usersPerProvince = await this._businessCaseService.fetchBusinessCaseAccountsByProvince(province);
  }

  public getCollaboratorSelection(val: IStageCollaborationInput) {
    const stageSelections = this.selectedCollaborators.find(
      (data) => data.businessCaseStageName === val.businessCaseStageName
    );
    if (stageSelections) {
      stageSelections.ownerId = val.ownerId;
      stageSelections.reviewerIds = [...val.reviewerIds];
      stageSelections.id = val.id;
    } else {
      this.selectedCollaborators.push(val);
    }
  }

  public showBuildingNumberInputDialog(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    const dialogRef = this._dialog.open(BuildingNumberInputDialogComponent, {
      width: '600px',
      height: '200px'
    });

    dialogRef.beforeClosed().subscribe({
      next: (data: BuildingData) => {
        if (data) {
          this.businessCaseForm.patchValue({
            buildingNumber: data.ONEVIEW,
            outlet: data.PLTXT
          });
        } else {
          this.businessCaseForm.get('buildingNumber')?.markAsDirty();
          this.businessCaseForm.get('buildingNumber')?.markAsTouched();
        }
      }
    });
  }
}
