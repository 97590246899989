import {
  AfterViewInit,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SoftServicesService } from '../../../services/soft-services.service';
import { IRateCardDataType } from '../../../models/rate-card-data';
import { SoftServiceCategories } from '../../../enums/soft-service-categories.enum';
import { filter, firstValueFrom } from 'rxjs';
import { ActivatedRoute, ParamMap, Router, RouterLink } from '@angular/router';
import { IAddSoftServicesPayload } from '../../../models/add-soft-services-payload.interface';
import { ISoftServicesBusinessCaseDetails } from '../../../models/soft-service-business-case-details';
import { IViewSoftServices } from '../../../models/view-soft-services';
import { IBusinessCaseTableItem } from '../../../models/business-case-table-item';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTooltip } from '@angular/material/tooltip';
import { ToastrService } from 'ngx-toastr';
import { IBusinessCaseOverview } from '../../../interfaces/business-case-overview.interface';
import { BusinessCaseService } from '../../../services/business-case.service';
import { IBusinessCaseDetails } from '../../../interfaces/business-case-details.interface';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import {
  backOfficeRouteKeys,
  BusinessCaseHeaderComponent
} from '@bidvest/shared';
import { IBusinessCaseStageConfig } from '../../../interfaces/business-case-stage-config.interface';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddSoftServicesUpdate } from '../../../models/add-soft-services-update.interface';

@Component({
  selector: 'bidvest-add-soft-services-overlay',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    ReactiveFormsModule,
    MatIconModule,
    MatPaginatorModule,
    MatMenuModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatCheckboxModule,
    FormsModule,
    MatTooltip,
    BusinessCaseHeaderComponent,
    RouterLink
  ],
  templateUrl: './add-soft-services-overlay.component.html',
  styleUrl: './add-soft-services-overlay.component.scss'
})
export class AddSoftServiceOverlayComponent
  implements OnInit, AfterViewInit, OnChanges {
  @ViewChild(MatPaginator) public paginator!: MatPaginator;
  public dataSource = new MatTableDataSource<IRateCardDataType>([]);
  // @ts-ignore
  public rateCardData: IViewSoftServices;
  public softServicesData!: Array<AddSoftServicesUpdate>;
  public businessCaseId!: number;
  public businessCaseDetails: IBusinessCaseDetails | undefined;
  public size: number = 100;
  public searchValue: string = '';
  public categoryValue: string = '';
  public resultsLength: number = 0;
  public filterCategoriesKeys = Object.keys(SoftServiceCategories);
  public readonly businessCaseIdValue = inject<{ businessCaseId: number, businessCaseDetails: IBusinessCaseDetails }>(MAT_DIALOG_DATA);
  public selectedServices = new SelectionModel<any>(true, []);
  public disableButton: boolean = false;

  public displayedColumns: Array<string> = [
    'code',
    'serviceName',
    'category',
    'subCategory',
    'unitCost',
    'uom',
    'quantity'
  ];
  public routes: { [key: string]: string } = backOfficeRouteKeys;
  public businessCaseStagesConfigs: Array<IBusinessCaseStageConfig> = [];
  constructor(
    private readonly _softService: SoftServicesService,
    private readonly _route: ActivatedRoute,
    private readonly _toaster: ToastrService,
    private readonly _businessCaseService: BusinessCaseService,
    private readonly _router: Router,
    private readonly _loader: NgxUiLoaderService,
    private readonly _dialog: MatDialogRef<AddSoftServiceOverlayComponent>,

  ) { }

  public readonly selection = new SelectionModel<IRateCardDataType>(true, []);

  public isAllSelected(): boolean {
    const numSelected: number = this.selection.selected.length;
    const numRows: number = this.dataSource.data.length;
    return numSelected === numRows;
  }

  public async ngOnInit(): Promise<void> {
    this.isEditor();
    this.isReviewer();
    await this.getAvailableServices();
    this.businessCaseDetails = this.businessCaseIdValue.businessCaseDetails;
    this.businessCaseId = this.businessCaseIdValue.businessCaseId;
    
    this.businessCaseStagesConfigs = await firstValueFrom(
      this._businessCaseService.fetchBusinessCaseStagesConfigs(
        // @ts-ignore
        this.businessCaseId
      )
    );

  }

  public ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.dataSource.data = changes['data'].currentValue;
  }

  public async triggerChange(): Promise<void> {
    await this.getAvailableServices();
  }

  public closeOverlay() {
    this._dialog.close();
    this._dialog.afterClosed().subscribe(() => {
      this._softService.triggerSoftServiceTableRefresh();
    });
  }

  public async getAvailableServices(): Promise<void> {
    this.rateCardData = await this._softService.fetchAvailableRateCardServices({
      category: this.categoryValue,
      currentPage: 1,
      searchTerm: this.searchValue,
      size: this.size
    }
    );
    this.dataSource = new MatTableDataSource(this.rateCardData.rateCards);
    this.resultsLength = this.rateCardData.rateCards.length;
  }

  public async getAddedSoftServices(): Promise<void> {
    this.softServicesData =
      await this._softService.fetchBusinessCaseSoftServices(
        this.businessCaseId
      );
    const inputElements = document.querySelectorAll(
      'input[data-id]'
    ) as NodeListOf<HTMLInputElement>;
    // loop through and update the value

    this.softServicesData.forEach((value) => {
      inputElements.forEach((input) => {
        if (Number(input.getAttribute('data-id')) === value.rateCard.id) {
          input.value = `${value.quantity}`;
        }
      });
    });
  }

  public async applyFilter(event: string): Promise<void> {
    this.categoryValue = event;
    await this.getAvailableServices();
  }

  public transformToSentenceCase(value: string): string {
    if (!value) return '';
    const formatted = value.replace(/_/g, ' ').toLowerCase();
    return formatted.charAt(0).toUpperCase() + formatted.slice(1);
  }

  public preventNegative(event: KeyboardEvent) {
    if (event.key === '-') {
      event.preventDefault();
    }
  }

  public async submit(): Promise<void> {

    try {
      this._loader.start();

      const payload: Array<IAddSoftServicesPayload> = [];

      const inputElements = document.querySelectorAll(
        'input[data-id]'
      ) as NodeListOf<HTMLInputElement>;
      inputElements.forEach((input) => {
        if (input.value && Number(input.value) > 0) {
          payload.push({
            rateCardId: Number(input.getAttribute('data-id')),
            quantity: Number(input.value)
          });
        } else {
          this._toaster.error(
            'Please ensure you enter a quantity that is more than zero.'
          );
        }
      });

      if (payload.length > 0) {
        await this._softService.addSoftService(payload, this.businessCaseId);
        this._loader.stop();

        this._toaster.success(
          'The soft services were successfully added to the business case.'
        );
        await this.getAddedSoftServices();
        this._dialog.close();
        this._dialog.afterClosed().subscribe(() => {
          this._softService.triggerSoftServiceTableRefresh();
        });
      } else {
        this._toaster.error(
          'Please ensure you enter a quantity for at least one soft service item.'
        );
      }
    } catch (error) {
      console.log(error);
      this._loader.stop();
      this._toaster.error(
        `There was an updating business case ${this.businessCaseDetails?.referenceNumber} soft services.`
      );
    }
  }

  public async review(): Promise<void> {
    try {
      this._loader.start();

      await firstValueFrom(
        this._businessCaseService.submitBusinessCaseForReview({
          businessCaseId: this.businessCaseId as number,
          businessCaseStageId: this.businessCaseDetails?.activeStageId as number
        })
      );

      this._loader.stop();

      this._toaster.success(
        `Business Case ${this.businessCaseDetails?.referenceNumber} has been submitted for review`
      );

      await this._router.navigateByUrl(
        this.routes['BUSINESS_CASE'] + '/' + this.businessCaseId
      );
    } catch (error) {
      console.log(error);
      this._toaster.error(
        `There was an error submitting business case ${this.businessCaseDetails?.referenceNumber} for review.`
      );
    }
  }

  public isEditor(): boolean {
    return this.businessCaseStagesConfigs.find(
      (config) => config.stageName === this.businessCaseIdValue.businessCaseDetails.activeStageName
    )?.canEdit as boolean;
  }

  public isReviewer(): boolean {
    return this.businessCaseStagesConfigs.find(
      (config) => config.stageName === this.businessCaseDetails?.activeStageName
    )?.canRead as boolean;
  }
}
