import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { IBusinessCaseAsset } from '../../../interfaces/business-case-asset.interface';
import { AssetListService } from '../../../services/asset-list.service';
import { IAssetDecline } from '../../../models/asset-decline.interface';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'bidvest-reject-asset-price-dialog',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatDialogClose,
    MatDialogContent,
    MatFormFieldModule,
    MatInputModule,
    MatDividerModule
  ],
  templateUrl: './reject-asset-price-dialog.component.html',
  styleUrl: './reject-asset-price-dialog.component.scss'
})
export class RejectAssetPriceDialogComponent implements OnInit {
  public assetRejectionForm!: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { assetDetails: IBusinessCaseAsset },
    private _fb: FormBuilder,
    private _assetListService: AssetListService,
    private _dialogRef: MatDialogRef<RejectAssetPriceDialogComponent>,
    private _toastr: ToastrService
  ) {}

  public ngOnInit(): void {
    this.assetRejectionForm = this._fb.group({
      sapId: [''],
      sapName: [''],
      reasonForRejection: ['', Validators.required]
    });

    this.prepopulateForm();
  }

  public prepopulateForm(): void {
    const sapIdControl = this.assetRejectionForm.get('sapId');
    sapIdControl?.patchValue(this.data.assetDetails.equipmentId);
    sapIdControl?.disable();

    const sapNameControl = this.assetRejectionForm.get('sapName');
    sapNameControl?.patchValue(this.data.assetDetails.discipline);
    sapNameControl?.disable();
  }

  public async submitAssetRejection(): Promise<void> {
    if (this.assetRejectionForm.valid) {
      const payload: IAssetDecline = {
        assetDeclined: true,
        declineReason: this.assetRejectionForm.get('reasonForRejection')?.value
      };
      try {
        await this._assetListService.declineAssetQuote(
          this.data.assetDetails.id,
          payload
        );

        this._toastr.success(`Asset declined successfully`);

        this._dialogRef.close(true);
      } catch (error) {
        this._toastr.error(`An error occurred`);
        this._dialogRef.close(false);
      }
    }
  }
}
