import {
  Component,
  Input,
  OnInit
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { IBusinessCaseSoftServiceQuoteItem } from '../../../models/business-case-soft-service-quote-item';
import { IBusinessCaseSoftServiceQuote } from '../../../models/business-case-soft-service-quote';
import { backOfficeRouteKeys } from '@bidvest/shared';
import {IBusinessCaseEngineeringServiceQuote} from "../../../models/business-case-engineering-service-quote";
import {IBusinessCaseEngineeringServiceQuoteItem} from "../../../models/business-case-engineering-service-quote-item";
import {RoundOffByTwoPipe} from "../../../../../../../../shared/src/lib/shared/pipes/round-off-by-two.pipe";
@Component({
  selector: 'bidvest-engineering-services-quotes-table',
  standalone: true,
  imports: [
    MatTableModule,
    MatPaginatorModule,
    MatButtonModule,
    MatCheckboxModule,
    RoundOffByTwoPipe
  ],
  templateUrl: './engineering-service-quote-table.component.html',
  styleUrl: './engineering-service-quote-table.component.scss'
})
export class EngineeringServiceQuoteTableComponent implements OnInit {
  protected readonly routes = backOfficeRouteKeys;
  @Input()
  public engineeringServiceQuote: IBusinessCaseEngineeringServiceQuote | undefined;
  public displayedColumns: string[] = [
    'highLevelDescription',
    'equipmentDescription',
    'assetCount',
    'mechanicalMonthlyFee',
    'mechanicalReactiveFeeWithWarranty',
    'mechanicalReactiveFeeWithoutWarranty',
    'electricalMonthlyFee',
    'electricalReactiveFeeWithWarranty',
    'electricalReactiveFeeWithoutWarranty',

  ];
  public dataSource = new MatTableDataSource<IBusinessCaseEngineeringServiceQuoteItem>(
    []
  );
  constructor() {}

  public async ngOnInit(): Promise<void> {
    // @ts-ignore
    this.dataSource = this.engineeringServiceQuote?.serviceItemModels;
  }
}
