<div class="rounded-lg w-full bg-[#f5faff] p-4">
  <div class="flex flex-row justify-between divide-x-2 border-b-2 mb-4 py-4">
    <div
      class="w-1/4 flex flex-col justify-start px-4"
      *ngIf="includesSoftServices()"
    >
      <h4 class="font-normal">Total soft service cost</h4>
      <span class="text-orange-400 font-bold text-2xl"
        >R {{ overview?.totalSoftServicesCost }}</span
      >
    </div>
    <div
      *ngIf="includesEngineeringServices()"
      class="w-1/4 flex flex-col justify-start px-4"
    >
      <h4 class="font-normal">Total engineering service cost</h4>
      <span class="text-orange-400 font-bold text-2xl"
        >R {{ overview?.totalEngineeringServicesCost }}</span
      >
    </div>
    <div class="w-1/4 flex flex-col justify-start px-4">
      <h4 class="font-normal">Stage owner</h4>
      <span class="text-orange-400 font-bold text-2xl">
        <bidvest-user-avatar
          [abbreviatedName]="getStageOwnerAbbreviatedName()"
        ></bidvest-user-avatar>
      </span>
    </div>
    <div class="w-1/4 flex flex-col justify-start items-start px-4">
      <h4 class="font-normal">Status</h4>
      <span
        class="py-2 px-4 rounded-full  text-white font-semibold text-sm inline-block {{
          statusConfig
        }}"
        >{{ transformToSentenceCase(overview?.activeStageStatus) }}</span
      >
    </div>
  </div>

  <div
    class="flex flex-row justify-between items-center gap-4 overflow-x-auto whitespace-nowrap w-full py-0"
  >
    @for (stage of stages; track $index) {
    <bidvest-business-case-stage-indicator
      [stages]="stage"
      [overview]="overview"
    ></bidvest-business-case-stage-indicator>
    }
  </div>
</div>
