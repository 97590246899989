import {
  Component,
  Input,
  OnInit
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { IBusinessCaseSoftServiceQuoteItem } from '../../../models/business-case-soft-service-quote-item';
import { IBusinessCaseSoftServiceQuote } from '../../../models/business-case-soft-service-quote';
import { backOfficeRouteKeys } from '@bidvest/shared';
@Component({
  selector: 'bidvest-services-quotes-table',
  standalone: true,
  imports: [
    MatTableModule,
    MatPaginatorModule,
    MatButtonModule,
    MatCheckboxModule
  ],
  templateUrl: './service-quote-table.component.html',
  styleUrl: './service-quote-table.component.scss'
})
export class ServiceQuotesTableComponent implements OnInit {
  protected readonly routes = backOfficeRouteKeys;
  @Input()
  public softServiceQuote: IBusinessCaseSoftServiceQuote | undefined;
  public displayedColumns: string[] = [
    'serviceName',
    'serviceDescription',
    'quantity',
    'unitCost',
    'vat',
    'totalCost'
  ];
  public dataSource = new MatTableDataSource<IBusinessCaseSoftServiceQuoteItem>(
    []
  );
  constructor() {}

  public async ngOnInit(): Promise<void> {
    // @ts-ignore
    this.dataSource = this.softServiceQuote.serviceItemModels;
    console.log(this.softServiceQuote?.serviceItemModels);
        
  }
}
