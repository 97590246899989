
  <span ngProjectAs="business-case-header-title">
    <div class="flex flex-col bg-white rounded-md mb-8">
      <p class="text-primary-blue font-bold text-2xl">Rate cards</p>
      <p class="text-gray-400 text-sm">
      </p>
    </div>
  </span>
<div class="overflow-scroll" >
<bidvest-rate-card-table
(rateCardId)="handleRateCardId($event)"
>
</bidvest-rate-card-table>
</div>
