<div class="w-full overflow-x-auto border-2 border-solid">
  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="caseNumber">
      <th mat-header-cell *matHeaderCellDef>Reference number</th>
      <td mat-cell *matCellDef="let businessCase">
        {{ businessCase.referenceNumber }}
      </td>
    </ng-container>

    <ng-container matColumnDef="businessCaseName">
      <th mat-header-cell *matHeaderCellDef>Business case name</th>
      <td
        mat-cell
        *matCellDef="let businessCase"
        [matTooltip]="businessCase.businessCaseName"
      >
        @if (businessCase.businessCaseName !== null &&
        businessCase.businessCaseName !== undefined) {
        {{ businessCase.businessCaseName }}
        } @else { <span class="text-xs">N/A</span> }
      </td>
    </ng-container>

    <ng-container matColumnDef="implementationDate">
      <th mat-header-cell *matHeaderCellDef>Implementation date</th>
      <td mat-cell *matCellDef="let businessCase">
        @if (businessCase.implementationDate !== null &&
        businessCase.implementationDate !== undefined) {
        {{ businessCase.implementationDate | date }}
        } @else { <span class="text-xs">N/A</span> }
      </td>
    </ng-container>

    <ng-container matColumnDef="initiationDate">
      <th mat-header-cell *matHeaderCellDef>Initiation date</th>
      <td mat-cell *matCellDef="let businessCase">
        @if (businessCase.initiationDate !== null && businessCase.initiationDate
        !== undefined) {
        {{ businessCase.initiationDate | date }}
        } @else { <span class="text-xs">N/A</span> }
      </td>
    </ng-container>

    <ng-container matColumnDef="buildingNumber">
      <th mat-header-cell *matHeaderCellDef>Building number</th>
      <td mat-cell *matCellDef="let businessCase">
        @if (businessCase.buildingNumber !== null && businessCase.buildingNumber
        !== undefined) {
        {{ businessCase.buildingNumber }}
        } @else { <span class="text-xs">N/A</span> }
      </td>
    </ng-container>

    <ng-container matColumnDef="handler">
      <th mat-header-cell *matHeaderCellDef>Handler</th>
      <td mat-cell *matCellDef="let businessCase">
        @if (businessCase.activeStageOwner !== null &&
        businessCase.activeStageOwner !== undefined) {
        {{ businessCase.activeStageOwner }}
        } @else { <span class="text-xs">N/A</span> }
      </td>
    </ng-container>

    <ng-container matColumnDef="stage">
      <th mat-header-cell *matHeaderCellDef>Stage</th>
      <td mat-cell *matCellDef="let businessCase">
        <div
          class="py-1 px-4 bg-[#0074D6] rounded text-white font-bold flex flex-row justify-center items-center text-xs text-nowrap"
        >
          {{ transform(businessCase.activeStage) }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let businessCase">
        @if (businessCase.activeStageStatus === 'DRAFT') {
        <div
          class="rounded-full py-1 px-4 flex flex-row justify-center items-center text-white font-bold bg-[#8B8A93] text-xs
          "
          
        >
          {{ transform(businessCase.activeStageStatus) }}
        </div>
      } @else if (businessCase.activeStageStatus === 'REJECTED') {
        <div
          class="rounded-full py-1 px-4 flex flex-row justify-center items-center text-white font-bold bg-[#B80000] text-xs
          "
          
        >
          {{ transform(businessCase.activeStageStatus) }}
        </div>
      } @else if (businessCase.activeStageStatus === 'IN_REVIEW') {
        <div
          class="rounded-full py-1 px-4 flex flex-row justify-center items-center text-white font-bold bg-orange-500 text-xs
          "
          
        >
          {{ transform(businessCase.activeStageStatus) }}
        </div>
      }
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let businessCase">
        <button
          mat-icon-button
          (click)="
            viewBusinessCase(businessCase.id, businessCase.activeStageStatus)
          "
        >
          <mat-icon>visibility</mat-icon>
        </button>
        <button
          mat-icon-button
          (click)="
            deleteBusinessCase(businessCase.id, businessCase.referenceNumber)
          "
        >
          <mat-icon>delete_outline</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      class="table-row"
    ></tr>

    <tr class="w-full" *matNoDataRow>
      <td [colSpan]="displayedColumns.length">
        <div class="h-96 flex flex-row justify-center items-center font-bold">
          No Data
        </div>
      </td>
    </tr>
  </table>
</div>

<div class="border-2 border-solid border-t-0">
  <mat-paginator
    [pageSizeOptions]="[10, 20, 30, 40, 50]"
    showFirstLastButtons
    aria-label="Select page of business cases"
  >
  </mat-paginator>
</div>
